export function defaultAntiDumpingsJson() {
  return {
    antidumping_need: null,
    antidumping_id: null,
    rate_description: null,
    description: null
  }
}

export function defaultExciseJson() {
  return {
    description: null,
    excise_id: null,
    excise_need: null,
    rate_description: null,
    transit_excise_id: null,
  };
}

export function defaultCustomsDutyJson() {
  return {
    duty_id: null,
    rate_description: null,
    description: null
  }
}

export function defaultCorrectionReasonJson() {
  return {
    basis_reason:null,
    customs_cost_reason:null,
    origin_country_reason:null,
    other_reason:null,
    payments_reason:null,
    quantity_and_weight_reason:null,
    stage_reason:null,
    tn_ved_reason:null,
  }
}