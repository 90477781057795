<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
    order="4"
    cols="6"
  >
    <div
      class="statistics-box pa-3 ma-0"
      style="position: relative"
    >
      <btn-can-edit
        v-show="!payer_json.declarant"
        :can-edit.sync="canEdit"
        left-indent="50%"
      />
      <financial-collapsed
        v-show="collapsed"
        :payer="payer"
        @open="collapsed = false"
      >
        <v-switch
          :input-value="payer_json.declarant"
          hide-details="auto"
          dense
          color="#5CB7B1"
          :disabled="contagentQueue"
          class="ml-4"
          @change="changeDeclarant"
        >
          <template #label>
            <label style="font-size: 12px">Декларант</label>
          </template>
        </v-switch>
      </financial-collapsed>
      <v-row
        v-show="!collapsed"
        :class="{'blocked-box': disabledView || canEdit}"
      >
        <v-col
          cols="12"
          class="d-flex align-center justify-space-between"
        >
          <span class="font-weight-bold">
            9 Лицо, ответственное за финансовое<br> урегулирование
          </span>
          <div class="d-flex justify-end">
            <v-switch
              v-model="payer_json.individual"
              hide-details="auto"
              dense
              class="mx-4"
              :disabled="contagentQueue"
              color="#5CB7B1"
              @change="changeIndividual"
            >
              <template #label>
                <label style="font-size: 12px">Физ. лицо</label>
              </template>
            </v-switch>
            <v-switch
              :input-value="payer_json.declarant"
              hide-details="auto"
              dense
              color="#5CB7B1"
              :disabled="contagentQueue"
              @change="changeDeclarant"
            >
              <template #label>
                <label style="font-size: 12px">Декларант</label>
              </template>
            </v-switch>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pb-0 d-flex"
        >
          <div
            style="width: 12%"
            class="mr-2"
          >
            <label>
              Страна
              <v-autocomplete
                ref="country_letter"
                v-model="payer_json.country_letter"
                auto-select-first
                :filter="filterBySearchField"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="countries"
                item-text="letterCode"
                item-value="letterCode"
              />
            </label>
          </div>
          <div
            v-show="!payer_json.individual"
            class="mr-2"
            style="width: 20%"
          >
            <label>
              УНП (ИНН И т.п.)
              <v-text-field
                ref="payer_inn"
                v-model="payer.payer_inn"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="!payer_json.individual"
            style="width: 68%"
          >
            <label>
              Наименование организации
              <v-text-field
                ref="payer_name"
                v-model="payer.payer_name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="payer_json.individual"
            class="mr-2"
            style="width: 20%"
          >
            <label>
              Код док-а
              <v-autocomplete
                ref="identity_doc_code"
                v-model="payer_json.identity_doc_code"
                :items="identityDocumentsFiltered"
                item-text="search"
                auto-select-first
                item-value="code"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
              />
            </label>
          </div>
          <div
            v-show="payer_json.individual"
            class="mr-2"
            style="width: 23%"
          >
            <label>
              Номер документа
              <v-text-field
                ref="personal_number"
                v-model="payer.payer_identity_doc_number"
                v-mask="'SS#######'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="payer_json.individual"
            style="width: 23%"
            class="mr-2"
          >
            <label>
              Дата документа
              <custom-date-picker
                ref="identity_doc_d_on"
                v-model="payer_json.identity_doc_d_on"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </label>
          </div>
          <div
            v-show="payer_json.individual"
            style="width: 26%"
          >
            <label>
              Личный номер
              <v-text-field
                ref="personal_number__custom"
                v-model="payer_json.personal_number"
                v-mask="'#######S###SS#'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
        </v-col>
        <v-col
          v-show="payer_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="payer_last_name"
              v-model="payer.payer_last_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-show="payer_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Имя
            <v-text-field
              ref="payer_first_name"
              v-model="payer.payer_first_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-show="payer_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Отчество
            <v-text-field
              ref="payer_middle_name"
              v-model="payer.payer_middle_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Индекс
            <v-text-field
              ref="postal_index"
              v-model="payer_json.postal_index"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <label>
            Область/Район
            <v-text-field
              ref="region"
              v-model="payer_json.region"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <label>
            Город/Деревня
            <v-text-field
              ref="city"
              v-model="payer_json.city"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="8"
          class="pb-0"
        >
          <label>
            Улица
            <v-text-field
              ref="street_house"
              v-model="payer_json.street_house"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Дом
            <v-text-field
              ref="house"
              v-model="payer_json.house"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0 pl-0"
        >
          <label>
            Номер помещ.
            <v-text-field
              ref="room"
              v-model="payer_json.room"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <!--  -->
        <v-col
          v-if="!contacts.length"
          class="d-flex align-end justify-start"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon>
            Контакт
          </v-btn>
        </v-col>
        <v-col
          ref="contacts"
          cols="8"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
        </v-col>

        <!-- Филиал -->
        <v-col
          v-show="!payer_json.individual"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div
                class="font-weight-bold d-flex align-center mt-2"
                :class="{ 'mb-2': collapsedBranch }"
              >
                Филиал
                <v-btn
                  style="z-index: 2"
                  min-width="32px"
                  class="ml-1"
                  text
                  @click="collapsedBranch = !collapsedBranch"
                >
                  <v-icon>
                    {{ collapsedBranch ? "mdi-chevron-down" : "mdi-chevron-up" }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsedBranch">
            <v-col
              cols="12"
              class="pb-0 d-flex"
            >
              <div
                style="width: 12%"
                class="mr-2"
              >
                <label>
                  Страна
                  <v-autocomplete
                    ref="branch_country_letter"
                    v-model="payer_json.branch_country_letter"
                    auto-select-first
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    :items="countries"
                    item-text="letterCode"
                    item-value="letterCode"
                    :filter="filterBySearchField"
                  />
                </label>
              </div>
              <div
                class="mr-2"
                style="width: 20%"
              >
                <label>
                  УНП (ИНН И т.п.)
                  <v-text-field
                    ref="payer_branch_inn"
                    v-model="payer.payer_branch_inn"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
              <div style="width: 68%">
                <label>
                  Наименование организации
                  <v-text-field
                    ref="payer_branch_name"
                    v-model="payer.payer_branch_name"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="branch_postal_index"
                  v-model="payer_json.branch_postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="branch_region"
                  v-model="payer_json.branch_region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="branch_city"
                  v-model="payer_json.branch_city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="8">
              <label>
                Улица
                <v-text-field
                  ref="branch_street_house"
                  v-model="payer_json.branch_street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Дом
                <v-text-field
                  ref="branch_house"
                  v-model="payer_json.branch_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0 pl-0"
            >
              <label>
                Номер помещ.
                <v-text-field
                  ref="branch_room"
                  v-model="payer_json.branch_room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
      <!--  -->
      </v-row>
    </div>
  </v-col>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import SingleContact from "../single-contact.vue";
import { mapGetters } from "vuex";
import FinancialCollapsed from "./financial-collapsed.vue";
import cloneDeep from "lodash.clonedeep";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { highlightField } from "@/helpers/control";
import { payer as onPayer } from "@/events/statistics/control";
import blockVisibility from "@/mixins/block-visibility";
import BtnCanEdit from "./btn-can-edit.vue";

export default {
  components: {
    CustomDatePicker,
    FinancialCollapsed,
    SingleContact,
    BtnCanEdit,
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  props: {
    contagentQueue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    block_id: "kdt-payer",
    canEdit: true,
    collapsed: true,
    collapsedBranch: true,
    payer: {
      declaration_id: null,
      payer_branch_inn: "",
      payer_branch_name: "",
      payer_first_name: "",
      payer_identity_doc_number: "",
      payer_inn: "",
      payer_last_name: "",
      payer_middle_name: "",
      payer_name: "",
    },
    payer_json: {
      individual: false,
      branch_city: "",
      branch_country_letter: "",
      branch_country_name: "",
      branch_house: "",
      branch_postal_index: "",
      branch_region: "",
      branch_room: "",
      branch_street_house: "",
      city: "",
      country_letter: "",
      country_name: "",
      house: "",
      identity_doc_code: "",
      identity_doc_d_on: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      street_house: "",
      declarant: null,
    },
    contacts: [],
    branch_contacts: [],
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      aeos: "kdt/getNsiAeos",
      disabledView: "kdt/getVisibility",
    }),
    identityDocumentsFiltered() {
      if (this.payer_json.country_letter) {
        if (
          ["BY", "RU", "KZ", "KG", "AM"].includes(
            this.payer_json.country_letter
          )
        ) {
          return this.identityDocuments.filter((item) => {
            return item.code.startsWith(this.payer_json.country_letter);
          });
        }
        return this.identityDocuments.filter((item) => {
          return item.code.startsWith("XX");
        });
      }
      return this.identityDocuments;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onPayer.subscribe(this.onControl);
  },
  beforeDestroy() {
    onPayer.unsubscribe();
  },
  methods: {
    onControl(path) {
      this.collapsed = false;
      this.highlightField(path);
    },
    highlightField,
    changeDeclarant(isDeclarant) {
      if (isDeclarant) {
        this.clearState();
      }
      this.payer_json.declarant = isDeclarant;
      this.collapsed = isDeclarant;
      this.$emit("changeContagentQueue", true);
    },
    clearState() {
      Object.keys(this.payer)
        .filter((key) => !["declaration_id"].includes(key))
        .forEach((key) => (this.payer[key] = null));
      Object.keys(this.payer_json).forEach(
        (key) => (this.payer_json[key] = null)
      );
      this.contacts = [];
      this.branch_contacts = [];
    },
    changeIndividual() {
      if (this.payer_json.individual === false) {
        this.payer_json.personal_number = null;
        this.payer.payer_identity_doc_number = null;
        this.payer_json.identity_doc_d_on = null;
        this.payer.payer_last_name = null;
        this.payer.payer_first_name = null;
        this.payer.payer_middle_name = null;
      } else {
        this.payer.payer_inn = null;
        this.payer.payer_name = null;
      }
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addContactItem(type = "") {
      const contact = {
        code: "",
        name: "",
        number: "",
      };
      if (type === "branch") {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === "branch") {
        this.branch_contacts.splice(index, 1);
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    contactsUpdater(payload, type) {
      if (type === "branch") {
        this.branch_contacts[payload.index] = payload.cont_item;
      } else {
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    setFields() {
      const payer = this.selected.payer;
      const payer_json = this.selected.payer.payer_json;

      const contacts = payer_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);

      Object.keys(this.payer).forEach((key) => {
        if (key in payer) {
          this.payer[key] = payer[key];
        }
      });

      Object.keys(this.payer_json).forEach((key) => {
        if (key in payer_json) {
          this.payer_json[key] = payer_json[key];
        }
      });

      if (!this.payer_json.declarant) this.collapsed = false;
    },
    uploadData() {
      const payer = this.convertEmptyStringsToNull({
        ...this.payer,
        payer_json: {
          ...this.payer_json,
          country_name:
            getCountryNameByCode(
              this.countries,
              this.payer_json.country_letter
            ) || null,
          branch_country_name:
            getCountryNameByCode(
              this.countries,
              this.payer_json.branch_country_letter
            ) || null,
          aeo_country_name:
            getCountryNameByCode(
              this.countries,
              this.payer_json.aeo_country_letter
            ) || null,
          contacts: this.contacts,
          branch_contacts: [],
        },
      });
      return this.$store
        .dispatch("kdt/uploadBlockData", {
          name: "payer",
          value: payer,
        })
        .then((response) => {
          this.$emit("changeContagentQueue", false);
          return response;
        });
    },
  },
};
</script>
