<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex flex-wrap"
    cols="12"
    style="padding: 0px"
  >
    <v-col cols="4">
      <v-autocomplete
        v-model="deferred_payment_item.payment_type_code"
        auto-select-first
        :items="paymentCodes"
        outlined
        dense
        item-text="search"
        item-value="code"
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </v-col>
    <v-col
      class="guarantee__date"
      cols="4"
    >
      <custom-date-picker
        ref="date"
        v-model="deferred_payment_item.deferred_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :label="'Дата'"
      />
    </v-col>
    <v-col
      cols="2"
      d-flex
    >
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        @click="deleteDeferredPayment"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewDeferredPayment"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { mapGetters } from "vuex";

export default {
  components: { CustomDatePicker },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    base: {
      required: true,
      type: Object,
    },
    all: {
      required: true,
      type: Array,
    },
    amount: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    block_id: null,
    deferred_payment_item: {
      payment_type_code: null,
      deferred_date: null,
    },
    paymentCodes: [],
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
    }),
    paymentCode() {
      return this.params?.item?.code || null;
    },
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `kdt-deferred-payment-item-${this.index}`;
        for (let key in newVal) {
          if (key in this.deferred_payment_item) {
            this.deferred_payment_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.fetchNsiPaymentCodes();
  },
  methods: {
    deleteDeferredPayment() {
      this.$emit("deleteDeferredPayment", this.index);
    },
    addNewDeferredPayment() {
      this.$emit("addNewDeferredPayment");
    },
    uploadData() {
      const deferred_payments_json = [...this.all];
      deferred_payments_json[this.index] = {
        ...this.deferred_payment_item,
      };
      const deferred_payment = convertEmptyStringsToNull({
        ...this.base,
        deferred_payments_json: deferred_payments_json,
      });
      return this.$store.dispatch("kdt/uploadBlockData", {
        name: "deferred_payment",
        value: deferred_payment,
      });
    },
    fetchNsiPaymentCodes() {
      this.$store.dispatch("catalogs/fetchNsiPaymentTypes").then((res) => {
        this.paymentCodes = res.data.list.map((i) => ({
          ...i,
          search: `${i.code}-${i.name}`,
        }));
      });
    },
  },
};
</script>
