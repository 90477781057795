<template>
  <v-row justify="end">
    <v-btn
      width="32px"
      height="32px"
      min-width="32px"
      class="ma-1 btn-edit"
      :style="btnEditStyle"
      @click="cnangeCanEdit"
    >
      <v-icon> mdi-pencil </v-icon>
    </v-btn>
  </v-row>
</template>
<script>
export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    leftIndent: {
      type: String,
      default: "30%",
    },
  },
  computed: {
    btnEditStyle() {
      return `
        position: absolute;
        top: 10px;
        left: ${this.leftIndent};
        z-index: 3;
      `
    },
  },
  methods: {
    cnangeCanEdit() {
      this.$emit("update:canEdit", !this.canEdit);
    },
  },
};
</script>

<!-- <style scoped>
.btn-edit {
  position: absolute;
  top: 12px;
  left: 30%;
  z-index: 3;
}
</style> -->
