<template>
  <v-row class="mb-16">
    <v-col
      cols="5"
      class="px-4"
    >
      <label>Доп. единица
        <field-menu>
          <v-list-item @click="copyAddMeasureToAll">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copyAddMeasure">
            Применить к выбранным товарам
          </v-list-item>
          <v-list-item
            :disabled="!item.add_measure_unit_digit"
            @click="fillWithWareDetails"
          >
            &sum; товаров списком для выбранных товаров
          </v-list-item>
        </field-menu>
      </label>
      <div class="d-flex mb-2">
        <input-numeric
          ref="add_measure_unit_quantity"
          v-model="item.add_measure_unit_quantity"
          :fraction="2"
          class="mr-1"
          style="width: 60%;"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-autocomplete
          ref="add_measure_unit_digit"
          v-model="item.add_measure_unit_digit"
          auto-select-first
          item-text="shortDescription"
          item-value="code"
          :items="measureUnits"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :filter="filterBySearchField"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Вес брутто (кг)
        <input-numeric
          ref="gross_weight"
          v-model="item.gross_weight"
          :fraction="null"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="4"
      class="px-2"
    >
      <div class="d-flex align-end">
        <div>
          <label>
            Вес нетто (кг)
          </label>
          <input-numeric
            ref="net_weight"
            v-model="item.net_weight"
            :fraction="null"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </div>
        <span class="py-2 mx-1">/</span>
        <div>
          <label>
            (без упаковки)
          </label>
          <input-numeric
            ref="net_weight_no_pack"
            v-model="item.net_weight_no_pack"
            :fraction="null"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </div>
      </div>
    </v-col>
    <component
      :is="activeComponent"
      :show.sync="show"
      :params="params"
    />
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getMeasureUnitShortName} from '@/helpers/catalogs'
import {normalizeFloat} from "@/helpers/math";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import customFieldChecker from '@/mixins/custom-field-checker'
import InputNumeric from "@/components/ui/input-numeric.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import {
  showDynamicComponentInjector,
} from "@/helpers/injectors";
import {ADD_MEASURE_TYPE} from "@/components/kdt/goods/fill-with-utils";

export default {
  components: {FieldMenu, InputNumeric},
  mixins: [filterBySearchFieldMixin, HighlightFieldWareSector, copyFieldMixin, customFieldChecker],
  inject: {
    showDynamicComponent:{
      from:showDynamicComponentInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    activeComponent: null,
    show: false,
    params: {
      type: ADD_MEASURE_TYPE,
      filter: {
        measureUnitLetter: null,
      }
    },
    item: {
      id: "",
      gross_weight: "",
      net_weight: "",
      net_weight_no_pack: "",
      add_measure_unit_digit: "",
      add_measure_unit_letter: "",
      add_measure_unit_quantity: "",
      tracked_measure_unit_digit: "",
      tracked_measure_unit_letter: "",
      tracked_measure_unit_quantity: "",
      controlled_measure_unit_digit: "",
      controlled_measure_unit_letter: "",
      controlled_measure_unit_quantity: "",
    },

  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      selectedWares: "kdt/getWaresWhichHaveSortIndex",
      packages: "catalogs/getNsiPackageTypes",
      countries: "catalogs/getNsiCountries",
      measureUnits: "catalogs/getNsiMeasureUnits",
    }),
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  methods: {
    fillWithWareDetails() {
      const params = {
        type: ADD_MEASURE_TYPE,
        filter:{
          measureUnitLetter:getMeasureUnitShortName(this.measureUnits, this.item.add_measure_unit_digit)
        }
      }
      this.showDynamicComponent({
        params,
        component:() => import('@/components/kdt/goods/fill-with-ware-details.vue')
      })
    },
    copyAddMeasure() {
      this.copy([
        'add_measure_unit_quantity',
        'add_measure_unit_digit'],
      [
        this.item.add_measure_unit_quantity,
        this.item.add_measure_unit_digit
      ], 'apply')
    },
    copyAddMeasureToAll() {
      this.copyToAll([
        'add_measure_unit_quantity',
        'add_measure_unit_digit'
      ],
      [
        this.item.add_measure_unit_quantity,
        this.item.add_measure_unit_digit
      ])
    },
    replaceFloatNumbers({value, prop, afterPoint = null}) {
      this.item[prop] = normalizeFloat({value, afterPoint});
    },
    onParamsChange({id, payload}) {
      if (id === this.item.id) {
        const {gross_weight, net_weight} = payload;
        this.item.gross_weight = gross_weight;
        this.item.net_weight = net_weight
        this.readyToUpdate();
      }
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
        tracked_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.tracked_measure_unit_digit),
        controlled_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.controlled_measure_unit_digit),
        add_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.add_measure_unit_digit),
      }
      this.$emit("update", payload);
    },
    setFields() {
      const ware = {...this.selectedWares[this.index]}
      Object.keys(this.item).forEach(key => {
        if (key in ware) {
          this.item[key] = ware[key]
        }
      })
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
  },
};
</script>

<style scoped>
th {
  font-size: 10px !important;
}

td {
  font-size: 12px !important;
}
</style>
