<template>
  <v-col
    cols="12"
    class="goods-description__block px-1"
  >
    <div class="tab-grey">
      <div
        ref="description"
        class="goods-description__text-wrapper"
        style="background: white"
      >
        <v-textarea
          v-model="state.description"
          label="Описание"
          :counter="1000"
          :counter-value="total"
          rows="3"
          auto-grow
          loading="false"
          background-color="white"
          hide-details="auto"
          class="description-text textarea-bottom-solid pt-3"
          :error="total > 1000"
        />
        <description-menu
          :value="state.description"
          field="description"
        />
      </div>
      <div
        ref="specific_description"
        class="goods-description__text-wrapper"
        style="background: white"
      >
        <v-textarea
          v-model="state.specific_description"
          label="Специальное описание товара"
          :counter="1000"
          :counter-value="total"
          rows="2"
          auto-grow
          loading="false"
          background-color="white"
          hide-details="auto"
          class="description-text textarea-bottom-solid pt-3"
          :error="total > 1000"
        />
        <description-menu
          :value="state.specific_description"
          field="specific_description"
        />
      </div>
    </div>
    <Portal :to="'tnved-extra-modal-' + item.id">
      <div
        v-if="combinedDescription"
        class="rounded mb-2 teal lighten-1 white--text py-4 px-6"
      >
        {{ combinedDescription }}
      </div>
    </Portal>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import { googleImage } from "@/helpers/text";
import DescriptionMenu from "@/components/kdt/goods/description-menu.vue";
import { patchStateInjector } from "@/helpers/injectors";
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
export default {
  name: "GoodsDescription",
  components: { DescriptionMenu },
  mixins: [highlightFieldWareSector],
  inject: {
    patchState: {
      from: patchStateInjector,
    },
  },
  props: {
    visited: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    wareDetails: {
      type: Array,
      required: true,
    },
    showQuantity: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    debug: true,
    showConfirm: false,
    waresListText: "",
    tab: 0,
    selectedTab: {},
    add_goods_by_list: false,
    state: {
      specific_description: null,
      description: null,
    },
  }),
  computed: {
    // Верхняя часть описания заполняется через "Список"
    ...mapGetters({
      selected: "kdt/getSelected",
      selectedWares: "kdt/getSelectedWares",
    }),
    combinedDescription() {
      return [this.item?.description, this.item.specific_description]
        .join("; ")
        .trim();
    },
  },
  watch: {
    state: {
      handler() {
        if (this.visited) this.patchState(this.state);
      },
      deep: true,
    },
    selectedWares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setFields() {
      const ware = this.selectedWares[this.index];
      Object.keys(this.state).forEach((key) => {
        if (key in ware) {
          this.state[key] = ware[key];
        }
      });
    },
    googleImage,
    total() {
      return [this.state.description, this.state.specific_description]
        .filter((v) => v)
        .join("; ").length;
    },
  },
};
</script>
<style scoped>
.goods-description__block,
.goods-description__text-wrapper {
  position: relative;
}
.textarea-bottom-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}
</style>
