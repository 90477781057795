<template>
  <div
    class="d-flex align-center mt-2"
    style="gap: 20px"
  >
    <div
      class="d-flex"
      style="width: 40%"
    >
      <v-autocomplete
        v-model="state.type"
        :items="availableTypes"
        auto-select-first
        item-text="text"
        item-value="value"
        outlined
        dense
        style="width: 50%"
        class="mr-4"
        hide-details="auto"
        background-color="white"
        :menu-props="{
          maxHeight: '100%',
        }"
      />
      <v-autocomplete
        v-model="state.including_to"
        :items="availableIncludings"
        auto-select-first
        item-text="text"
        item-value="value"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="selectCurrency"
      />
    </div>
    <div style="width: 120px">
      <input-numeric
        v-model="state.cost"
        :fraction="2"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div style="width: 120px">
      <v-autocomplete
        v-model="state.currency_letter"
        :disabled="
          state.including_to === 1 &&
            state.currency_letter === invoiceCurrencyLetter
        "
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </div>
    <div style="width: 220px">
      <v-text-field
        v-model="range"
        outlined
        placeholder="Все товары"
        dense
        hide-details="auto"
        background-color="white"
        @change="onRangeChange"
      />
    </div>
    <div
      class="d-flex"
      style="width: 72px"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('remove', index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === items.length - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import { defaultIncludingCostItem } from "@/components/kdt/including-cost/including-cost";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {
  isCorrectStartEnd,
  isGoodsRangeValid,
  trimRange,
} from "@/helpers/inputs";
import { TYPES, INCLUDINGS } from "@/constants/including-costs-catalogs";

export default {
  components: { InputNumeric },
  mixins: [filterBySearchFieldMixin],
  props: {
    visited: {
      required: true,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      state: defaultIncludingCostItem(),
      range: "",
    };
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      currencies: "catalogs/getNsiCurrencies",
    }),
    invoiceCurrencyLetter() {
      return this.selected.about_wares.invoice_currency_letter;
    },
    typeDeclaration() {
      return this.selected?.type_declaration?.declaration_kind_code;
    },
    availableTypes() {
      return TYPES.filter((type) =>
        type.available.includes(this.typeDeclaration)
      );
    },
    availableIncludings() {
      return INCLUDINGS.filter((type) =>
        type.available.includes(this.typeDeclaration)
      );
    },
  },
  watch: {
    item: {
      handler(value) {
        this.state = value;
        this.range = value.ware_range ?? "";
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler(value) {
        if (this.visited) this.$emit("update", { index: this.index, value });
      },
      deep: true,
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
  },
  methods: {
    selectCurrency(including) {
      if (including === 1) {
        this.state.currency_letter = this.invoiceCurrencyLetter;
      }
    },
    onRangeChange(value) {
      this.range = trimRange(value);
      if (!isCorrectStartEnd(this.state.ware_range)) {
        return this.$error("Неверное начало и конец диапазона товаров");
      }
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.state.ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.state.ware_range;
        });
      }
    },
  },
};
</script>
