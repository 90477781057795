<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex flex-wrap"
    cols="12"
    style="padding: 0px;"
  >
    <v-col 
      class="guarantee__code"
      cols="4"
    >
      <v-tooltip
        bottom
        :disabled="!warranty_item.code || hideTooltips"
        :label="'Код способа'"
      >
        <template #activator="{ on }">
          <v-autocomplete
            ref="code"
            v-model="warranty_item.code"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
            :items="guaranteeTypes"
            
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
            v-on="on"
          />
        </template>
      </v-tooltip>
    </v-col>
    <v-col cols="4">
      <input-numeric
        v-model="warranty_item.sum"
        background-color="white"
        hide-details="auto"
        :fraction="2"
        :label="'Сумма гарантии'"
        @input="(v) => (warranty_item.sum = v.replace(',', '.'))"
      />
    </v-col>
    <v-col 
      cols="4"
    >      
      <v-autocomplete
        ref="currency_letter"
        v-model="warranty_item.currency_letter"
        auto-select-first
        :items="currencies"
        :label="'Валюта'"
        item-text="letterCode"
        background-color="white"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        :filter="filterBySearchField"
      />
    </v-col>
    <v-col
      class="guarantee__number"
      cols="4"
    >
      <v-text-field
        ref="number"
        v-model="warranty_item.number"
        class="mr-1"
        :label="'Номер гарантии'"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="50"
      />
    </v-col>
    <v-col
      class="guarantee__date"
      cols="4"
    >
      <custom-date-picker
        ref="date"
        v-model="warranty_item.date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :label="'Дата'"
      />
    </v-col>    
    <v-col
      class="guarantee__inn"
      cols="4"
    >
      <v-text-field
        ref="inn"
        v-model="warranty_item.inn"
        v-mask="'#########'"
        background-color="white"
        :label="'УНП'"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
      />
    </v-col>
    <v-col
      class="guarantee__bank"
      cols="4"
    >
      <v-autocomplete
        ref="bank_id"
        v-model="warranty_item.bank_id"
        item-text="CDBank"
        background-color="white"
        item-value="CDBank"
        :items="banks"
        :label="'Код банка'"
        auto-select-first
        dense
        outlined
        hide-details="true"
      />
    </v-col>
    <v-col
      cols="2"
      d-flex
    >
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        @click="deleteWarranty"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewWarranty"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {mapGetters} from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {CustomDatePicker, InputNumeric},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  props: {
    banks: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    base: {
      required: true,
      type: Object
    },
    all: {
      required: true,
      type: Array
    },
    amount: {
      required: true,
      type: Number,
    },
    guaranteeTypes: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    block_id: null,
    warranty_item: {
      code: null,
      sum: null,
      currency_letter: null,
      number: null,
      date: null,
      inn: null,
      bank_id: null
    }
  }),
  computed: {
    ...mapGetters({
      currencies: "catalogs/getNsiCurrencies",
      selected:'kdt/getSelected',
      aeos:'kdt/getNsiAeos'
    }),
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `kdt-warranty-item-${this.index}`;
        for (let key in newVal) {
          if (key in this.warranty_item) {
            this.warranty_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    deleteWarranty() {
      this.$emit('deleteWarranty', this.index)
    },
    addNewWarranty() {
      this.$emit('addNewWarranty')
    },
    uploadData() {
      const guarantees_json = [...this.all]
      guarantees_json[this.index] = {
        ...this.warranty_item
      }
      const guarantee = convertEmptyStringsToNull({
        ...this.base,
        guarantees_json: guarantees_json
      })
      return this.$store.dispatch("kdt/uploadBlockData", {
        name: "guarantee",
        value: guarantee,
      });
    },
  },
};
</script>


