import axios from "axios";
import {KDT} from "@/http-routes";

export const ADD_MEASURE_TYPE = "ADD_MEASURE_TYPE"
export const SPECIFIC_MEASURE_TYPE = "SPECIFIC_MEASURE_TYPE"

export const SPECIFIC_MEASURE_FROM_WEIGHT_TYPE = "SPECIFIC_MEASURE_FROM_WEIGHT_TYPE"

export const WEIGHT_TYPES = [
  {
    name:"Брутто",
    value:"grossWeight"
  },
  {
    name:"Нетто",
    value:"netWeight"
  },
  {
    name:"Без упаковки",
    value:"netWeightNoPack"
  }
]

export const TYPES = [
  {
    type: ADD_MEASURE_TYPE,
    title:"Заполнить доп.единицу из товаров списком",
    weightTypeSelect: false,
    getFilteredWares: getByAddMeasureUnitLetter,
    submit: calculateAddMeasureUnitQuantityFromWareDetails
  },
  {
    type: SPECIFIC_MEASURE_FROM_WEIGHT_TYPE,
    title:"Заполнить спец. единицу из веса товара",
    weightTypeSelect: true,
    getFilteredWares: getBySpecificMeasureUnitLetter,
    submit: calculateSpecificMeasureUnitQuantityFromWeight
  },
  {
    type: SPECIFIC_MEASURE_TYPE,
    title:"Заполнить спец. единицу из доп.единицы товара",
    weightTypeSelect: false,
    getFilteredWares: getWithSameSpecificAndAddMeasureUnitLetter,
    submit: calculateSpecificMeasureUnitQuantityFromAddMeasureUnitQuantity
  }
]

export function getTypeDetails(type){
  return TYPES.find(element => element.type === type)
}

function getWithSameSpecificAndAddMeasureUnitLetter({declarationId}) {
  return axios.get(`${KDT}ware/getWithSameSpecificAndAddMeasureUnitLetter?declarationId=${declarationId}`)
}

function calculateSpecificMeasureUnitQuantityFromAddMeasureUnitQuantity({payload}) {
  return axios.post(`${KDT}ware/calculateSpecificMeasureUnitQuantityFromAddMeasureUnitQuantity`, payload)
}

function calculateSpecificMeasureUnitQuantityFromWeight({fromWeightField, payload}) {
  return axios.post(`${KDT}ware/calculateSpecificMeasureUnitQuantityFromWeight?fromField=${fromWeightField}`, payload)
}

function getBySpecificMeasureUnitLetter({declarationId, measureUnitLetter}) {
  return axios.get(`${KDT}ware/getBySpecificMeasureUnitLetter?declarationId=${declarationId}&specificMeasureUnitLetter=${measureUnitLetter}`)
}

function getByAddMeasureUnitLetter({declarationId, measureUnitLetter}) {
  return axios.get(`${KDT}ware/getByAddMeasureUnitLetter?declarationId=${declarationId}&addMeasureUnitLetter=${measureUnitLetter}`)
}

function calculateAddMeasureUnitQuantityFromWareDetails({declarationId, payload}) {
  return axios.post(`${KDT}ware/calculateAddMeasureUnitQuantityFromWareDetails?declarationId=${declarationId}`, payload)
}
