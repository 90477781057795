<template>
  <div
    id="dt-goods-block"
    ref="common"
  >
    <v-container fluid>
      <v-row class="mb-1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">4 Всего товаров ({{ waresWhichHaveSortIndex.length }})</span>
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-btn
                max-height="32px"
                min-width="32px"
                max-width="32px"
                text
                tabindex="-1"
                v-bind="attrs"
                class="ml-2 elevation-0 wares-menu"
                v-on="on"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="deleteAllGoods">
                Удалить все товары
              </v-list-item>
              <v-list-item @click="deleteSeveralGoods">
                Удалить несколько товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <goods-modal
            :show.sync="waresModal"
            :wares-params="waresParams"
          />
        </v-col>
      </v-row>
      <div class="statistics-goods-list">
        <goods-item
          v-for="(item, idx) in waresWhichHaveSortIndex"
          :key="'goods-items-' + idx"
          ref="goodsItem"
          :item="item"
          :index="idx"
        />
      </div>

      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-end"
      >
        <v-btn
          color="#5CB7B1"
          @click="addGoodsToKdt"
        >
          <v-icon>mdi-plus</v-icon> Добавить товар
        </v-btn>
      </v-col>
      <component
        :is="activeComponent"
        :ware-id="activeComponentProps.id"
        :ware-sort-index="activeComponentProps.sortIndex"
        :show.sync="showComponent"
      />
    </v-container>
  </div>
</template>
<script>
import GoodsItem from "./goods/goods-item.vue";
import { mapGetters } from "vuex";
import { wareDetailsModal } from "@/events/common";
import GoodsModal from "@/components/kdt/goods/goods-modal.vue";
import { addExtraTnvedRules } from "@/events/statistics";
import { wares as onWares } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
export default {
  components: {
    GoodsModal,
    GoodsItem,
  },
  data: () => ({
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    showComponent: false,
    activeComponent: null,
    activeComponentProps: {
      id: null,
      sortIndex: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      wares: "kdt/getSelectedWares",
    }),
    waresWhichHaveSortIndex() {
      return this.wares.filter((i) => i.sort_index).sort((a, b) => a.sort_index - b.sort_index);
    },
  },
  created() {
    addExtraTnvedRules.subscribe(this.onFillExtraTnved);
    wareDetailsModal.subscribe(this.openWareDetailsModal);
    onWares.subscribe(this.highlightField);
  },
  beforeDestroy() {
    addExtraTnvedRules.unsubscribe();
    wareDetailsModal.unsubscribe();
    onWares.unsubscribe();
  },
  methods: {
    highlightField,
    onFillExtraTnved() {
      this.activeComponent = () =>
        import("@/components/kdt/goods/tnved/add-extra-tnved-rules.vue");
      this.showComponent = true;
    },
    openWareDetailsModal({ sortIndex, id }) {
      this.activeComponent = () =>
        import("@/components/kdt/goods/ware-details/ware-details-modal.vue");
      this.activeComponentProps = {
        ...this.activeComponentProps,
        id,
        sortIndex,
      };
      this.showComponent = true;
    },
    deleteAllGoods() {
      this.$store
        .dispatch("kdt/deleteAllGoods")
        .then(() => this.$info("Действие применено"))
        .catch(() => this.$error("Ошибка"));
    },
    deleteSeveralGoods() {
      this.waresParams = { action: "delete" };
      this.waresModal = true;
    },
    addGoodsToKdt() {
      this.waresParams = { action: "add_sort_index" };
      this.waresModal = true;
    },
  },
};
</script>
