<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template :tabs="tabs" />
    <kdt-toolbox />
    <kdt-main-info />
    <v-container fluid>
      <v-row>
        <declarant />
        <sender
          :contagent-queue="senderQueue"
          @changeContagentQueue="changeFinancialQueue"
        />
        <recipient />
        <financial
          :contagent-queue="financialQueue"
          @changeContagentQueue="changeSenderQueue"
        />
      </v-row>
    </v-container>
    <kdt-place-date />
    <kdt-contract-info />
    <kdt-transport />
    <kdt-location />
    <documents />
    <goods-character v-if="!pinned.includes('goods-character')" />
    <kdt-goods />

    <v-container fluid>
      <v-row>
        <deferred-payment />
        <warranty />
      </v-row>
    </v-container>
    
    <kdt-including-cost />
    <kdt-graph-b-container />


    <kdt-copy-field-modal />
    <copy-field-to-all-modal />
    <catalogs />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import KdtToolbox from '@/components/kdt/kdt-toolbox.vue'
import KdtMainInfo from '@/components/kdt/kdt-main-info.vue'
import Declarant from '@/components/kdt/contragents/declarant.vue'
import Sender from '@/components/kdt/contragents/sender.vue'
import KdtPlaceDate from '@/components/kdt/kdt-place-date.vue'
import KdtContractInfo from '@/components/kdt/kdt-contract-info.vue'
import KdtTransport from '@/components/kdt/transport/transport.vue'
import KdtLocation from '@/components/kdt/location/kdt-location.vue'
import KdtIncludingCost from '@/components/kdt/including-cost/including-cost-list.vue'
import Documents from '@/components/kdt/documents/documents.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue"
import GoodsCharacter from '@/components/kdt/goods-character.vue'
import KdtGoods from '@/components/kdt/kdt-goods.vue'
import Financial from '@/components/kdt/contragents/financial.vue'
import Recipient from '@/components/kdt/contragents/recipient.vue'
// import offsetMixin from '@/mixins/offset.mixin.js'
// import SharedBuffer from "@/components/shared/shared-buffer.vue";
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import {setDeclarationTitle} from "@/helpers/text";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import KdtCopyFieldModal from "@/components/kdt/goods/kdt-copy-field-modal.vue";
import KdtGraphBContainer from "@/components/kdt/graph-b/graphbcontainer.vue"
import Warranty from '../../components/kdt/warranty/warranty.vue';
import DeferredPayment from '../../components/kdt/deferred-payment/deferred-payment.vue';

export default {
  components: {
    KdtCopyFieldModal,
    SideMenu,
    DistinctDivisionNotification,
    CopyFieldToAllModal,
    PinnedTemplate,
    KdtToolbox,
    KdtMainInfo,
    Declarant,
    Sender,
    KdtPlaceDate,
    KdtContractInfo,
    KdtTransport,
    KdtLocation,
    KdtIncludingCost,
    Documents,
    Catalogs,
    GoodsCharacter,
    KdtGoods,
    Financial,
    Recipient,
    KdtGraphBContainer,
    Warranty,
    DeferredPayment
  },
  provide: {
    rootNamespace: 'kdt'
  },
  data: () => ({
    menuItems: [
      {
        name: "Основная информация",
        id: "kdt-main-info"
      },
      {
        name: "Декларант",
        id: "kdt-declarant"
      },
      {
        name: "Получатель",
        id: "kdt-recipient"
      },
      {
        name: "Отправитель",
        id: "kdt-sender"
      },
      {
        name: "Плательщик",
        id: "kdt-payer"
      },
      {
        name: "Место и дата",
        id: "kdt-place-date"
      },
      {
        name: "Сведения из договора",
        id: "kdt-deal-details"
      },
      {
        name: "Транспорт",
        id: "kdt-about-transport"
      },
      {
        name: "Местонахождение товаров",
        id: "kdt-ware-location"
      },
      {
        name: "Документы",
        id: "kdt-documents"
      },
      {
        name: "Общие характеристики товара",
        id: "kdt-goods-character"
      },
      {
        name: "Товары",
        id: "kdt-goods-block",
        component: WaresScroll,
      },
      {
        name: "Включение в стоимость",
        id: 'kdt-including-cost'
      }
    ],
    tabs: [
      {
        name: 'documents',
        component: () => import('@/components/kdt/documents/documents.vue')
      },
      {
        name: 'goods-character',
        component: () => import('@/components/kdt/goods-character.vue')
      },
      {
        name: 'controls',
        component: () => import('@/components/kdt/controls/common-errors.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/kdt/controls/payment-errors.vue'),
      }
    ],
    senderQueue: false,
    financialQueue: false
  }),
  computed: {
    ...mapGetters({
      selected: 'kdt/getSelected',
      pinned: "ui/getPinned",
    }),
  },
  watch: {
    selected: {
      handler(kdtDeclaration) {
        const {remark, out_number: outNumber} = kdtDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "КДТ"
        })
        this.changeType();
      },
      deep: true
    }
  },
  created() {
    this.setExtraTnvedOptions()
    this.fetchPaymentTypes()
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('kdt/fetchNsiAeos')
    this.$store.dispatch('kdt/fetchNsiTransportMarks')
    const id = this.$route.params.id
    this.$store.dispatch("kdt/getDocumentById", id).catch(err => {
      if (err.response.status === 403) {
        this.$router.push('/403')
      }
    });
  },
  methods: {
    fetchPaymentTypes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then((res) => {
        this.$store.commit('kdt/SET_PAYMENT_TYPES', res.data)
      })
    },
    changeType() {
      if (this.selected.type_declaration) {
        this.declarant_type = this.selected.type_declaration.customs_procedure
      }
    },
    changeFinancialQueue(e) {
      this.financialQueue = e
    },
    changeSenderQueue(e) {
      this.senderQueue = e
    },
    setExtraTnvedOptions() {
      this.$store.dispatch('catalogs/fetchExtraTnvedOptions').then((res) => {
        const {names_11, names_12, names_13, names_14} = res.data
        this.$store.commit('kdt/SET_EXTRA_TNVED_OPTIONS', {
          firstOptions: names_11,
          secondOptions: names_12,
          thirdOptions: names_13,
          fourthOptions: names_14
        })
      })
    }
  },
}
</script>
