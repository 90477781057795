<template>
  <v-row class="pt-0">
    <v-col
      cols="7"
      class="pb-0"
    >
      <span class="font-weight-bold text-body-2">
        <v-checkbox
          tabindex="-1"
          dense
          hide-details="true"
          color="#5CB7B1"
          :value="isAllSelected"
          class="float-left m-0"
          @change="checkAll"
        />
        <div class="pt-2">
          40 Предшествующий документ
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                :disabled="!selectedDocs.length"
                @click="copyToAll('preceding_documents', selectedDocs)"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item
                :disabled="!selectedDocs.length"
                @click="deleteDocumentArr"
              >
                Удалить выбранные документы
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </span>
    </v-col>
    <v-col
      cols="5"
      class="pb-0 pt-5"
    >
      <span class="font-weight-bold text-body-2 pt-2">
        39 Квота
        <v-menu
          v-if="index === 0"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="mx-1"
              tabindex="-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-tune</v-icon>
          </template>
          <v-list>
            <v-list-item @click="$emit('copy', 'all')">
              Применить блок ко всем товарам
            </v-list-item>
            <v-list-item @click="$emit('copy', 'selected')">
              Применить блок к выбранным товарам
            </v-list-item>
            <v-list-item @click="deleteQuote"> Удалить квоту </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-col>
    <v-col cols="7">
      <v-row v-if="!preceding_documents.length">
        <v-col
          cols="3"
          class="pr-1"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addDocument"
          >
            <v-icon>mdi-plus</v-icon> Добавить документ
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="mb-2"
      >
        <v-col cols="12">
          <document
            v-for="(item, idx) in preceding_documents"
            :key="idx"
            :document="item"
            :index="idx"
            :ware-index="index"
            :control="control"
            :documents="preceding_documents"
            :selected-docs="selectedDocs"
            @select="onSelect"
            @update="onDocumentUpdate"
            @add="addDocument"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="!item.quotas_json.length"
      cols="5"
    >
      <v-btn
        dense
        class="elevation-0"
        @click="addQuote"
      >
        <v-icon>mdi-plus</v-icon> Добавить квоту
      </v-btn>
    </v-col>
    <v-col
      v-else
      cols="5"
    >
      <v-row>
        <quote
          v-for="(quota, containerIndex) in item.quotas_json"
          :key="`quota-${containerIndex}`"
          :index="containerIndex"
          :item="quota"
          :visited="visited"
          :length="item.quotas_json.length"
          @add="addQuote"
          @change="onQuotaChange"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Document from "./document.vue";
import cloneDeep from "lodash.clonedeep";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import Quote from "../quote.vue";

export default {
  components: { Document, Quote },
  mixins: [HighlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    control: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedDocs: [],
    preceding_documents: [],
    item: {
      quotas_json: [],
    },
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      doc_types: "catalogs/getNsiTypesOfDocument",
      selected_wares: "kdt/getWaresWhichHaveSortIndex",
      // shipmentIndex:'kdt/getShipmentIndex'
    }),
    isAllSelected() {
      return (
        this.selectedDocs.length &&
        this.selectedDocs.length === this.preceding_documents.length
      );
    },
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    preceding_documents: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  methods: {
    // квоты
    addQuote() {
      const value = {
        measure_unit_digit: "",
        measure_unit_letter: "",
        quantity: "",
      };
      if (this.item.quotas_json.length === 2) {
        return this.$snackbar({
          text: "Достигнуто максимальное кол-во квот",
          color: "red",
          top: false,
          right: false,
        });
      } else {
        this.item.quotas_json.push(value, value);
        this.readyToUpdate();
      }
    },
    deleteQuote() {
      this.item.quotas_json = [];
      this.readyToUpdate();
    },
    onQuotaChange({ index, value }) {
      this.item.quotas_json.splice(index, 1, value);
      this.readyToUpdate();
    },
    onSelect(id) {
      if (this.selectedDocs.includes(id)) {
        this.selectedDocs = this.selectedDocs.filter((i) => i !== id);
      } else {
        this.selectedDocs.push(id);
      }
    },
    checkAll() {
      if (this.selectedDocs.length === this.preceding_documents.length) {
        this.selectedDocs = [];
      } else {
        this.selectedDocs = this.preceding_documents.map((i) => i.id);
      }
    },
    setFields() {
      const wares = cloneDeep(
        this.selected_wares[this.index].preceding_documents
      );
      this.preceding_documents = wares || [];
      const item = { ...this.selected_wares[this.index] };
      Object.keys(this.item).forEach((key) => {
        if (key in item) {
          this.item[key] = item[key];
        }
      });
    },
    updateEditableGoodsItem() {
      const preceding_documents = this.preceding_documents || [];
      const quotas_json = this.item.quotas_json || [];
      this.$emit("update", { preceding_documents, quotas_json });
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
    onDocumentUpdate({ index, value }) {
      this.preceding_documents.splice(index, 1, value);
      this.updateEditableGoodsItem();
    },
    addDocument() {
      const payload = {
        ware_id: this.info.id,
        sort_index: this.preceding_documents.length + 1,
      };
      this.$store
        .dispatch("kdt/addPrecedingDocument", payload)
        .then((res) => {
          this.preceding_documents.push(res.data);
          this.readyToUpdate();
        })
        .catch(() => {
          this.$snackbar({ text: "Ошибка", color: "red", top: false });
        });
    },
    deleteDocumentArr() {
      this.$store
        .dispatch("kdt/deletePrecedingDocument", this.selectedDocs)
        .then(() => {
          this.preceding_documents = this.preceding_documents.filter(
            (i) => !this.selectedDocs.includes(i.id)
          );
          this.selectedDocs = [];
          this.updateEditableGoodsItem();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
  },
};
</script>
