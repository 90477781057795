<template>
  <v-row>
    <v-col
      cols="6"
    >
      <label>
        Вид связи
        <v-autocomplete
          v-model="cont_item.code"
          auto-select-first
          :items="types"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          item-text="text"
          item-value="code"
          @change="updatedField"
        />
      </label>
    </v-col>
    <v-col
      cols="6"
    >
      <label>
        Номер
        <hint v-if="cont_item.code=== 'TE' && showHint" />
        <v-text-field
          v-if="phoneMask && cont_item.code === 'TE'"
          key="field-with-mask"
          v-model="cont_item.number"
          v-mask="phoneMask"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="updatedField"
        >
          <template
            v-if="!hideActions"
            #append-outer
          >
            <v-icon @click="addContactItem">mdi-plus</v-icon>
            <v-icon
              class="ml-1"
              @click="deleteContactItem"
            >mdi-delete-outline
            </v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-else
          key="field-without-mask"
          v-model="cont_item.number"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :error="wrongPhoneNumber"
          @change="updatedField"
        >
          <template
            v-if="!hideActions"
            #append-outer
          >
            <!-- <v-icon @click="addContactItem">mdi-plus</v-icon> -->
            <v-icon
              class="ml-1"
              @click="deleteContactItem"
            >mdi-delete-outline
            </v-icon>
          </template>
        </v-text-field>
      </label>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {phoneRegExp} from "@/components/transit/regexp";
import Hint from "@/components/transit/hint.vue";
export default {
  components:{
    Hint
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    idx: {
      required: true,
      type: Number
    },
    types: {
      required: true,
      type: Array
    },
    contactsLength: {
      required: true,
      type: Number
    },
    hideActions: {
      default: false,
      type: Boolean
    },
    phoneMask: {
      required: false,
      validator:(v) => typeof v === 'string' || v === null
    },
    showHint:{
      required:false,
      type:Boolean,
      default: true
    }
  },
  data: () => ({
    cont_item: {
      code: "",
      name: "",
      number: ""
    }
  }),
  computed: {
    ...mapGetters({
      selected: 'transit/getSelected'
    }),
    wrongPhoneNumber(){
      return this.cont_item.code === 'TE' ? !phoneRegExp.test(this.cont_item.number) : false
    },
  },
  watch: {
    item: {
      handler(newVal) {
        for (let key in newVal) {
          if (key in this.cont_item) {
            this.cont_item[key] = newVal[key];
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    deleteContactItem() {
      this.$emit('deleteContactItem', this.idx)
    },
    addContactItem() {
      this.$emit('addContactItem')
    },
    updatedField() {
      this.$emit('contactsUpdater', {cont_item: this.cont_item, index: this.idx})
    }
  }
}
</script>
