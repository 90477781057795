<template>
  <v-col
    cols="12"
    class="statistics-box rounded-0"
  >
    <v-col cols="12">
      <v-row>
        <v-col
          cols="12"
          class="mx-0 px-1"
        >
          <div class="d-flex align-center justify-space-between">
            <div
              class="mr-2"
              style="width: 5%"
            >
              <label>
                № из ДТ
              </label>
              <v-text-field
                ref="dt_sort_index"
                v-model="item.dt_sort_index"
                outlined
                hide-details="auto"
                dense
                disabled
                background-color="grey lighten-2"
              />
            </div>
            <div
              class="mr-4"
              style="width: 7%"
            >
              <label>
                ЭГ / МПО
                <field-menu>
                  <v-list-item
                    @click="copyToAll('feature_code', item.feature_code)"
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="copy('feature_code', item.feature_code, 'apply')"
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
              </label>
              <v-autocomplete
                ref="correction_reason_code"
                v-model="item.feature_code"
                auto-select-first
                :items="['ЭКГ', 'МПО']"
                outlined
                hide-details="auto"
                dense
                background-color="white"
              />
            </div>
            <div class="mr-4">
              <label>
                16 Код товара
                <field-menu>
                  <v-list-item @click="showTNVED">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item @click="copyToAll('tn_ved', item.tn_ved)">
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item @click="copy('tn_ved', item.tn_ved, 'apply')">
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
              </label>
              <div class="d-flex align-center">
                <v-text-field
                  ref="tn_ved"
                  v-model="item.tn_ved"
                  v-mask="'##########'"
                  :error="invalidCode"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                  class="tn_ved mr-2"
                  @keypress.enter="showTNVED"
                  @change="tnvedChanged"
                />
                <v-btn
                  style="z-index: 2"
                  :class="{ 'teal lighten-2': tab === 0 }"
                  max-width="36px"
                  min-width="36px"
                  text
                  @click="toggleTreeView"
                >
                  <v-icon> mdi-file-tree-outline </v-icon>
                </v-btn>
                <v-btn
                  max-width="36px"
                  min-width="36px"
                  text
                  @click="showCodeInfo(item.tn_ved)"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </div>
            </div>
            <div
              style="width: 9%"
              class="mr-4"
            >
              <label>
                <field-menu>
                  <v-list-item @click="showExtraTNVED">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item
                    @click="copyToAll('add_tn_ved', item.add_tn_ved)"
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="copy('add_tn_ved', item.add_tn_ved, 'apply')"
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                  <v-list-item @click="openRulesModal">Заполнить по правилу</v-list-item>
                </field-menu>
              </label>
              <div class="d-flex align-center">
                <v-text-field
                  ref="add_tn_ved"
                  v-model="item.add_tn_ved"
                  v-mask="'####'"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                  class="add_tn_ved"
                  @keypress.enter="showExtraTNVED"
                />
                <tnved-tooltip :item="item" />
              </div>
            </div>
            <div class="bordered d-flex justify-center pa-2 py-2 mr-6 ml-2">
              <div
                v-for="checkbox of checkboxes"
                :key="checkbox.key"
              >
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      class="font-weight-bold teal--text cursor-pointer d-block px-1"
                      v-on="on"
                    >{{ checkbox.label }}</label>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="copyToAll(checkbox.key, item[checkbox.key])"
                    >
                      Применить ко всем товарам ({{ checkbox.label }})
                    </v-list-item>
                    <v-list-item
                      @click="copy(checkbox.key, item[checkbox.key], 'apply')"
                    >
                      Применить к выбранным товарам ({{ checkbox.label }})
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-checkbox
                  v-model="item[checkbox.key]"
                  dense
                  class="mx-0"
                  hide-details
                  color="black"
                  @change="readyToUpdate"
                />
              </div>
            </div>
            <div
              style="width: 11%"
              class="mr-4"
            >
              <label>
                34 Страна происх.
                <field-menu>
                  <v-list-item
                    @click="
                      copyToAll(
                        'origin_country_letter',
                        item.origin_country_letter
                      )
                    "
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="
                      copy(
                        'origin_country_letter',
                        item.origin_country_letter,
                        'apply'
                      )
                    "
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
              </label>
              <div class="d-flex">
                <v-autocomplete
                  ref="origin_country_letter"
                  v-model="item.origin_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  class="mr-2"
                  item-text="text"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
                <v-autocomplete
                  ref="origin_country_letter_b"
                  v-model="item.origin_country_letter_b"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="text"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </div>
            </div>
            <div
              class="d-flex mr-4"
              style="width: 24%"
            >
              <div>
                <label>
                  36
                  <field-menu>
                    <v-list-item @click="openPreferences">
                      Поиск в справочнике
                    </v-list-item>
                    <v-list-item
                      @click="copyToAll('preference_1', item.preference_1)"
                    >
                      Применить ко всем товарам
                    </v-list-item>
                    <v-list-item
                      @click="copy('preference_1', item.preference_1, 'apply')"
                    >
                      Применить к выбранным товарам
                    </v-list-item>
                  </field-menu>
                </label>
                <v-autocomplete
                  ref="preference_1"
                  v-model="item.preference_1"
                  :items="preferences_1"
                  item-text="code"
                  item-value="code"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  class="mr-2"
                  auto-select-first
                />
              </div>
              <div>
                <field-menu>
                  <v-list-item @click="openPreferences">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item
                    @click="copyToAll('preference_2', item.preference_2)"
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="copy('preference_2', item.preference_2, 'apply')"
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
                <v-autocomplete
                  ref="preference_2"
                  v-model="item.preference_2"
                  :items="preferences_2"
                  item-text="code"
                  item-value="code"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  class="mr-2"
                  auto-select-first
                />
              </div>
              <div>
                <field-menu>
                  <v-list-item @click="openPreferences">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item
                    @click="copyToAll('preference_3', item.preference_3)"
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="copy('preference_3', item.preference_3, 'apply')"
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
                <v-autocomplete
                  ref="preference_3"
                  v-model="item.preference_3"
                  :items="preferences_3"
                  item-text="code"
                  item-value="code"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  class="mr-2"
                  auto-select-first
                />
              </div>
              <div>
                <field-menu>
                  <v-list-item @click="openPreferences">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item
                    @click="copyToAll('preference_4', item.preference_4)"
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="copy('preference_4', item.preference_4, 'apply')"
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
                <v-autocomplete
                  ref="preference_4"
                  v-model="item.preference_4"
                  :items="preferences_4"
                  item-text="code"
                  item-value="code"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  auto-select-first
                />
              </div>
            </div>
            <div style="width: 9%">
              <label>37 Процедура
                <field-menu>
                  <v-list-item @click="openMovementFeatures">
                    Поиск в справочнике
                  </v-list-item>
                  <v-list-item
                    @click="
                      copyToAll(
                        'movement_feature_code',
                        item.movement_feature_code
                      )
                    "
                  >
                    Применить ко всем товарам
                  </v-list-item>
                  <v-list-item
                    @click="
                      copy(
                        'movement_feature_code',
                        item.movement_feature_code,
                        'apply'
                      )
                    "
                  >
                    Применить к выбранным товарам
                  </v-list-item>
                </field-menu>
              </label>
              <v-autocomplete
                ref="movement_feature_code"
                v-model="item.movement_feature_code"
                auto-select-first
                :items="movementFeatures"
                item-text="code"
                item-value="code"
                outlined
                hide-details="auto"
                dense
                background-color="white"
              >
                <template #prepend-inner>
                  <span
                    class="py-1 grey--text"
                    style="white-space: nowrap"
                  >{{ customsProcedure }} {{ previousCustomsProcedure }}</span>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-col>
        <ware-dynamic-tnved-tabs
          v-model="tab"
          class="pa-0"
          :tabs="tabs"
          :code="code"
        />
        <goods-description
          :control="control"
          :visited="visited"
          :index="index"
          :item="item"
          :ware-details="wareDetails"
          :show-quantity="showQuantity"
        />
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import GoodsDescription from "./description/index.vue";
import TnvedTooltip from "./tnved-tooltip.vue";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  showCatalog,
  catalogValueSelected,
  tnvedCodeSelected,
  tnvedCodeChanged,
  addExtraTnvedRules,
} from "@/events/statistics";
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import moment from "moment";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import { showCodeInfo } from "@/helpers/tnved";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import { addPromiseInjector, readyToUpdateInjector } from "@/helpers/injectors";

export default {
  name: "Description",
  components: {
    FieldMenu,
    WareDynamicTnvedTabs,
    GoodsDescription,
    TnvedTooltip,
  },
  mixins: [highlightFieldWareSector, copyFieldMixin, filterBySearchFieldMixin],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector,
    },
    addPromise: {
      from: addPromiseInjector,
    },
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
    showQuantity: {
      required: true,
      type: Boolean,
    },
    currentState: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    invalidCode: false,
    tab: null,
    tabs: [
      {
        title: "Показать структуру",
        id: 1,
        component: () => import("@/components/tnved/structure.vue"),
      },
    ],
    checkboxes: [
      {
        label: "С",
        key: "under_ban",
      },
      {
        label: "ИС",
        key: "intellectual_property",
      },
      {
        label: "М",
        key: "marking",
      },
      {
        label: "П",
        key: "traceability",
      },
    ],
    item: {
      id: "",
      declaration_id: "",
      tn_ved: "",
      add_tn_ved: "",
      under_ban: "",
      intellectual_property: "",
      marking: "",
      traceability: "",
      movement_feature_code: "",
      feature_code: "",
      preference_1: "",
      preference_2: "",
      preference_3: "",
      preference_4: "",
      origin_country_letter: "",
      origin_country_name: "",
      origin_country_letter_b: "",
      origin_country_name_b: "",
      dt_sort_index: "",
    },
    code: "",
    preferences_1: [],
    preferences_2: [],
    preferences_3: [],
    preferences_4: [],
  }),
  computed: {
    ...mapGetters({
      codePage: "tnved/getCodePage",
      selected: "kdt/getSelected",
      selectedWares: "kdt/getWaresWhichHaveSortIndex",
      correction_types: "catalogs/getNsiCorrectionReasons",
      cancellation_types: "catalogs/getNsiCancellationReasons",
      nsiTarifs: "catalogs/getNsiTarifs",
      movementFeatures: "catalogs/getNsiMovementFeatures",
      countries: "catalogs/getNsiCountries",
    }),
    customsProcedure() {
      return this.selected.type_declaration.customs_procedure;
    },
    previousCustomsProcedure() {
      return this.selected.type_declaration.previous_customs_procedure;
    },
    getExciseVisibility() {
      return this.customsProcedure === "ЭК"
        ? true
        : !this.nsiTarifs.find((i) => i === this.item.tn_ved);
    },
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeSelected.subscribe(this.checkAndUpdateTNVED);
    catalogValueSelected.subscribe(this.updatesResolver, [
      "ware/add_tn_ved",
      "ware/correction_reason_code",
      "ware/preferences",
      "ware/movement_feature_code",
    ]);
    this.$store.dispatch("catalogs/fetchNsiPreferences").then(({ data }) => {
      this.preferences_1 = data.list
        .filter((i) => i.type === 1)
        .map((i) => {
          return { ...i, search: `${i.code}-${i.name}`.toLowerCase() };
        });
      this.preferences_2 = data.list
        .filter((i) => i.type === 2)
        .map((i) => {
          return { ...i, search: `${i.code}-${i.name}`.toLowerCase() };
        });
      this.preferences_3 = data.list
        .filter((i) => i.type === 3)
        .map((i) => {
          return { ...i, search: `${i.code}-${i.name}`.toLowerCase() };
        });
      this.preferences_4 = data.list
        .filter((i) => i.type === 4)
        .map((i) => {
          return { ...i, search: `${i.code}-${i.name}`.toLowerCase() };
        });
    });
  },
  beforeDestroy() {
    tnvedCodeSelected.unsubscribe();
    catalogValueSelected.unsubscribe();
  },
  methods: {
    showCodeInfo,
    openRulesModal() {
      addExtraTnvedRules.trigger();
    },
    openPreferences() {
      showCatalog.trigger({
        type: "preferences",
        search: "",
        field: "item.preferences",
      });
    },
    copyPreferencesToAll() {
      const preferences = this.getAllPreferences();
      const { keys, values } = this.getKeysAndValues(preferences);
      this.copyToAll(keys, values);
    },
    copyPreferences() {
      const preferences = this.getAllPreferences();
      const { keys, values } = this.getKeysAndValues(preferences);
      this.copy(keys, values, "apply");
    },
    toggleTreeView() {
      this.tab = this.tab === 0 ? null : 0;
    },
    updatesResolver({ id, code, field, ...rest }) {
      if (id === this.item.id && code && field) {
        if (field === "ware/movement_feature_code") {
          this.updatesResolverMovement({ code, field, ...rest });
        } else {
          const [, key] = field.split("/");
          this.item[key] = code;
        }
        this.readyToUpdate();
      }
    },
    updatesResolverMovement({ id, field, code }) {
      if (id === this.item.id) {
        if (code && field) {
          if (field === "item.movement_feature_code") {
            this.item.movement_feature_code = code;
          }
          const [, key] = field.split("/");
          this.item[key] = code;
          this.readyToUpdate();
        }
      }
    },
    setFields() {
      const ware = this.selectedWares[this.index];
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
      this.code = ware.tn_ved;
    },
    updateEditableGoodsItem() {
      const description = convertEmptyStringsToNull({
        ...this.item,
        origin_country_name: getCountryNameByCode(
          this.countries,
          this.item.origin_country_letter
        ),
        origin_country_name_b: getCountryNameByCode(
          this.countries,
          this.item.origin_country_letter_b
        ),
      });
      this.$emit("update", description);
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    showExtraTNVED() {
      showCatalog.trigger({
        type: "tnved_extra",
        id: this.item.id,
        search: this.item.tn_ved,
        field: "ware/add_tn_ved",
        actionDate: this.getActionDate(),
      });
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.item.id,
        search: this.item.tn_ved,
      });
    },
    openMovementFeatures() {
      showCatalog.trigger({
        id: this.item.id,
        type: "movement_features",
        search: this.item.movement_feature_code,
        field: "ware/movement_feature_code",
      });
    },
    getActionDate() {
      const format = "DD.MM.YYYY";
      const today = moment();
      const lessThanElevenOctober = today.isBefore(
        moment("11.10.2023", format)
      );
      return moment(this.currentState?.shipment_date, "YYYY-MM-DD").isValid()
        ? moment(this.currentState?.shipment_date, "YYYY-MM-DD").format(format)
        : lessThanElevenOctober
          ? this.useNewCatalog
            ? moment("01.10.2023", format).format(format)
            : moment("30.09.2023", format).format(format)
          : today.format(format);
    },
    checkAndUpdateTNVED({ id, code }) {
      if (id === this.item.id) {
        this.item.tn_ved = code;
        this.code = code;
        this.checkTnved();
        this.checkExtraTnved();
        this.updateExciseQuantity();
        tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
        this.readyToUpdate();
      }
    },
    checkExtraTnved() {
      if ([null, ""].includes(this.item.tn_ved)) {
        this.item.add_tn_ved = "";
      } else {
        const promise = new Promise((resolve, reject) => {
          const actionDate = this.getActionDate();
          this.$store
            .dispatch("catalogs/getExtraTnved", {
              code: this.item.tn_ved,
              actionDate,
            })
            .then((res) => {
              if (res.data?.length === 1) {
                const [element] = res.data;
                const { code } = element;
                this.item.add_tn_ved = code === "0000" ? "0000" : "";
              } else {
                this.item.add_tn_ved = "";
              }
              resolve(res);
            })
            .catch((err) => reject(err));
        });
        this.addPromise(promise);
        return promise;
      }
    },
    tnvedChanged() {
      this.code = this.item.tn_ved;
      this.checkTnved();
      this.checkExtraTnved();
      this.updateExciseQuantity();
      tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
    },
    getDeclarationDate() {
      const dateFormat = "DD.MM.YYYY";
      return moment(
        this.selected.about_declaration.declaration_date,
        "YYYY-MM-DD"
      ).isValid()
        ? moment(
          this.selected.about_declaration.declaration_date,
          "YYYY-MM-DD"
        ).format(dateFormat)
        : moment().format(dateFormat);
    },
    checkTnved() {
      const declarationDate = this.getDeclarationDate();
      this.$store
        .dispatch("tnved/checkValidTnVed", {
          code: this.code,
          declarationDate,
        })
        .then((res) => {
          this.invalidCode = !res.data;
        });
    },
    updateExciseQuantity() {
      if (!this.nsiTarifs.includes(this.item.tn_ved)) {
        this.item.excise_quantity = "";
      }
    },
  },
};
</script>
<style scoped>
label {
  margin: 0;
}

.tn_ved {
  width: 150px;
}

.bordered {
  /* background: #fff; */
  border: 1px #6e6e6e solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}
</style>
