<template>
  <v-overlay
    :value="show"
  >
    <v-card
      color="#EDEDED"
      width="500"
      light
    >
      <v-toolbar
        color="#333"
      >
        <div class="text-md-body-1  white--text">
          Пересчитать ТС и платежи {{ titleIfFilledDeclarationFeatureDate }}
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        <v-btn
          class="mr-2 mb-2 green white--text"
          @click="recalculate"
        >
          Пересчитать
        </v-btn>
        <v-btn
          class="mr-2 mb-2 red white--text"
          @click="close"
        >
          Не пересчитывать
        </v-btn>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
import {mapGetters} from "vuex";
import {validateAndRecalculatePayments} from "@/components/kdt/controls/payments";
const RECALCULATE_ERROR = "Не удалось рассчитать платежи"
export default {
  props:{
    show:{
      type:Boolean,
      required:true
    },
    // есть ли дата ВДТ
    filledDeclarationFeatureDate:{
      type:Boolean,
      default: false
    },
  },
  computed:{
    ...mapGetters({
      requests: "kdt/getRequests",
      selected:"kdt/getSelected"
    }),
    titleIfFilledDeclarationFeatureDate() {
      return this.filledDeclarationFeatureDate ? "на дату ВДТ" : ""
    },
  },
  methods:{
    close(){
      this.$emit('update:show', false)
    },
    recalculateCustomsCost(){
      return this.$store.dispatch("kdt/recalculateCustomsCost", this.selected.id)
        .then(() => {
          this.$info("Платежи рассчитаны")
          this.update()
        })
        .catch((err) => {
          this.$error("Не удалось пересчитать ТС")
          throw err
        })
    },
    update(){
      return this.$store.dispatch("kdt/getDocumentById", this.selected.id)
        .catch((err) => {
          this.$error("Не удалось загрузить документ")
          throw err
        })
    },
    validateAndRecalculate() {
      return validateAndRecalculatePayments.call(this)
        .catch((error) => {
          this.$error(RECALCULATE_ERROR)
          throw error
        })
    },
    recalculate() {
      Promise.all(this.requests)
        .then(() => this.recalculateCustomsCost())
        .then(() => this.validateAndRecalculate())
        .then(() => this.close())
        .catch((err) => console.error(err))
    },
  }

}
</script>