<template>
  <v-dialog
    v-model="show"
    width="550"
    @click:outside="confirm(false)"
  >
    <v-card>
      <v-toolbar
        class="elevation-0"
        color="#333"
        dark
      >
        <div class="text-h6">
          Пересчет весовых параметров
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        <div class="mx-2">
          <label>
            Укажите {{ params.label }}
            <input-numeric
              v-model="weight"
              :fraction="2"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :suffix="suffix"
            /></label>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="confirm(false)"
        >
          Отмена
        </v-btn>
        <v-btn
          :disabled="!weight"
          class="elevation-0"
          color="#5cb7b1"
          @click="confirm(true)"
        >
          Пересчитать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {waitAsyncDecision} from "@/helpers/async-component";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {getWaresParamTotal} from "@/helpers/math";
import {mapGetters} from "vuex";

export default {
  components: {InputNumeric},
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required:true,
      type:Object
    }
  },
  data(){
    return{
      weight:null,
      asyncDecision:{
        resolve:null,
        reject:null
      }
    }
  },
  computed:{
    ...mapGetters({
      wares:'kdt/getSelectedWares',
    }),
    totalGrossWeight(){
      return getWaresParamTotal(this.wares, 'gross_weight')
    },
    grossWeightWithPackage(){
      return +this.totalGrossWeight + +this.weight
    },
    suffix(){
      if(this.params.field === "packageWeight" && this.weight){
        return `${this.grossWeightWithPackage} кг (брутто с упаковкой)`
      }
      return ""
    }
  },
  methods:{
    waitAsyncDecision,
    confirm(value){
      if(value){
        const weight = this.params.field === "packageWeight" ? this.grossWeightWithPackage : this.weight
        if(this.asyncDecision.resolve !== null) this.asyncDecision.resolve(weight)
      }else {
        if(this.asyncDecision.reject !== null) this.asyncDecision.reject()
      }
      this.close()
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
