<template>
  <v-overlay
    :value="show"
    class="prevent-trigger-update"
  >
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span> Выбор товаров</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
        class="my-3"
      />
      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col cols="2">
            <div class="d-flex align-center pb-3">
              <v-checkbox
                v-model="withWares"
                color="grey darken-3"
                :label="`Товары (${selectedWares.length}/${items.length})`"
                hide-details="true"
              />
            </div>
          </v-col>
          <v-col cols="10">
            <v-alert
              v-if="isDisabled"
              class="mb-0"
              dense
              text
              color="orange"
              type="warning"
            >
              Выберите товары
            </v-alert>
          </v-col>
        </v-row>
        <div>
          <div class="d-flex my-4">
            <v-text-field
              ref="rangeField"
              v-model="range"
              hide-details="auto"
              background-color="#EDEDED"
              class="ba-0 rounded-l-sm rounded-r-0"
              filled
              rounded
              placeholder="Введите диапазон"
              @keyup.enter="getIdsFromRange"
            />
            <v-btn
              height="auto"
              class="elevation-0 my-0 rounded-l-0"
              x-large
              dark
              min-width="50px"
              @click="getIdsFromRange"
            >
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-lg-block">Выбрать</span>
            </v-btn>
          </div>
          <div class="elements__table">
            <v-virtual-scroll
              :items="items"
              :bench="10"
              :item-height="50"
              :height="height"
            >
              <template #default="{ index, item }">
                <goods-modal-element
                  :item="item"
                  :index="index"
                  :selected="selectedWares.includes(item.id)"
                  @select="onItemSelect"
                />
              </template>
            </v-virtual-scroll>
          </div>
        </div>

        <div class="d-flex justify-end mt-4">
          <v-btn
            elevation="0"
            color="#EDEDED"
            class="mr-2"
            @click="unset({ cancel: true })"
          >
            Отмена
          </v-btn>
          <v-btn
            id="applyButton"
            :disabled="isDisabled"
            color="#5CB7B1"
            elevation="0"
            :dark="!isDisabled"
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {
  isGoodsRangeValid,
  rangeToArray,
  isCorrectStartEnd,
  trimRange,
} from "@/helpers/inputs";
import closeOnEscape from "@/mixins/close-on-escape.mixin.js";
import GoodsModalElement from "./goods-modal-element.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { applyToWares } from "@/events/common";
export default {
  components: {
    GoodsModalElement,
  },
  mixins: [closeOnEscape],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    id: {
      type: Number,
      required: false,
      default: -1,
    },
    waresParams: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    blocks: [
      {
        name: "Импортер/Экспортер",
        value: "subject",
      },
      {
        name: "Контрагент",
        value: "counter_agent",
      },
      {
        name: "Документы",
        value: "presented_documents",
      },
    ],
    withWares: false,
    items: [],
    selectedWares: [],
    range: "",
    validRange: "",
  }),
  computed: {
    ...mapGetters({
      requests: "kdt/getRequests",
      wares: "kdt/getSelectedWares",
    }),
    height() {
      return window.innerHeight - 500;
    },
    isDisabled() {
      return !this.selectedWares.length;
    },
    isDeleteAction() {
      return this.waresParams.action === "delete";
    },
    lastIndexKdtWares() {
      const arrayIndexes = this.wares
        .filter((i) => i.sort_index)
        .map((i) => i.sort_index);
      return arrayIndexes.length ? Math.max(...arrayIndexes) : null;
    },
  },
  watch: {
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    withWares(nv) {
      if (nv === true) {
        this.selectedWares = this.items.map((i) => i.id);
      } else {
        this.selectedWares = [];
      }
    },
    show: {
      async handler(nv) {
        if (nv === true) {
          // фокус на поле ввода при открытии окна
          this.$nextTick(() => this.$refs.rangeField.focus());
          await Promise.all(this.requests);
          this.loadWares().then((wares) => {
            this.items = this.filteredWares(wares).map((item, idx) => {
              const {
                id,
                tn_ved,
                add_tn_ved,
                net_weight,
                gross_weight,
                invoice_cost,
                shipment_date: date,
                invoice_currency_letter,
                origin_country_letter,
                title: titleDefault = "",
              } = item;
              const shipment_date = date
                ? moment(date).format("DD.MM.YYYY")
                : "";
              const title =
                titleDefault !== null && titleDefault.length > 120
                  ? titleDefault.slice(0, 130) + "..."
                  : titleDefault;
              const cost =
                invoice_cost && invoice_currency_letter
                  ? `${invoice_cost} ${invoice_currency_letter}`
                  : invoice_cost;
              return {
                id,
                index: idx + 1,
                tn_ved,
                add_tn_ved,
                title,
                shipment_date,
                net_weight,
                gross_weight,
                invoice_cost,
                cost,
                invoice_currency_letter,
                origin_country_letter,
              };
            });
          });
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.unset();
  },
  methods: {
    filteredWares(wares) {
      if (this.isDeleteAction) return wares.filter((i) => i.sort_index);
      else return wares.filter((i) => !i.sort_index);
    },
    loadWares() {
      if (this.id > 0) {
        return this.$store.dispatch("kdt/fetchGoods", this.id).then((res) => {
          return res.data;
        });
      } else {
        const items = this.$store.getters[`kdt/getSelectedWares`];
        return Promise.resolve(items);
      }
    },
    getText(err) {
      return typeof err === "object" && "response" in err
        ? err.response
        : err || "Ошибка";
    },
    submit() {
      if (this.waresParams.action === "apply") {
        if (Array.isArray(this.waresParams.field)) {
          const values = {};
          this.waresParams.field.forEach((key) => {
            values[key] = this.waresParams.value[key];
          });
          const payload = {
            ids: this.selectedWares,
            values,
          };
          this.$store
            .dispatch("kdt/applyToSeveral", payload)
            .then(() => {
              applyToWares.trigger();
              this.$snackbar({
                text: "Действие применено",
                top: false,
                right: false,
              });
              this.close();
            })
            .catch((err) => {
              this.$snackbar({
                text: this.getText(err),
                top: false,
                right: false,
                color: "red",
              });
            });
        } else {
          this.$store
            .dispatch("kdt/applyToSeveral", {
              ids: this.selectedWares,
              values: { [this.waresParams.field]: this.waresParams.value },
            })
            .then(() => {
              applyToWares.trigger();
              this.$snackbar({
                text: "Действие применено",
                top: false,
                right: false,
              });
              this.close();
            })
            .catch((err) => {
              this.$snackbar({
                text: this.getText(err),
                top: false,
                right: false,
                color: "red",
              });
            });
        }
      } else if (this.isDeleteAction) {
        this.$store
          .dispatch("kdt/deleteGoodsAmount", { ids: this.selectedWares })
          .then(() => {
            applyToWares.trigger();
            this.$snackbar({
              text: "Действие применено",
              top: false,
              right: false,
            });
            this.close();
          })
          .catch((err) => {
            this.$snackbar({
              text: this.getText(err),
              top: false,
              right: false,
              color: "red",
            });
          });
      } else if (this.waresParams.action === "add_sort_index") {
        this.addSortIndex();
      }
    },
    addSortIndex() {
      let lastIndex = this.lastIndexKdtWares;
      const selectedWares = this.wares.filter((i) =>
        this.selectedWares.includes(i.id)
      );
      const payload = selectedWares.map((item, index) => {
        return {
          ...item,
          sort_index: lastIndex ? lastIndex+=1 : index + 1,
        };
      });
      this.$store.dispatch("kdt/updateGoodsItem", payload)
        .then(() => {
          this.$info("Действие применено");
          this.close();
        })
        .catch((err) => this.$error(this.getText(err)));
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.validRange = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.validRange;
        });
      }
    },
    getIdsFromRange() {
      this.range = trimRange(this.range);
      if (!isCorrectStartEnd(this.range)) {
        return this.$snackbar({
          text: "Неверный диапазон",
          color: "red",
          top: false,
          right: false,
        });
      }
      const idxs = rangeToArray(this.range, true);
      const values = idxs.map((idx) => {
        return this.items[idx]?.id;
      });
      if (values.length > this.items.length) {
        this.range = "";
        return this.$snackbar({
          text: "Неверно указан диапазон. Кол-во выбранных товаров превышает имеющееся кол-во.",
          color: "red",
          top: false,
          right: false,
          timeout: 5000,
        });
      }
      this.selectedWares = values;
      // фокус на кнопку при нажатии Enter
      this.$nextTick(() => {
        document.querySelector("#applyButton").focus();
      });
    },
    unset({ cancel = false } = {}) {
      this.withWares = false;
      this.loading = false;
      this.range = "";
      if (cancel) {
        this.onSelectAll();
      } else {
        this.selectedWares = [];
        this.items = [];
      }
    },
    onSelectAll() {
      this.withWares
        ? (this.selectedWares = this.items.map((i) => i.id))
        : (this.selectedWares = []);
    },
    onItemSelect(id) {
      if (this.selectedWares.includes(id)) {
        this.selectedWares = this.selectedWares.filter((i) => i !== id);
      } else {
        this.selectedWares.push(id);
      }
    },
    close() {
      this.unset();
      this.$emit("update:show", false);
    },
  },
};
</script>
<style scoped>
.fit-content {
  width: fit-content;
}
</style>
