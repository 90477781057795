import store from "@/store"
import router from "@/router"


function _toggle() {
  if (!store.getters['ui/getPinned'].includes("payment-errors")) {
    store.commit('ui/TOGGLE_PIN', "payment-errors")
  }
}

function _error(text) {
  this.$snackbar({text, color: 'red', top: false})
}

function _calculate() {
  const id = router.currentRoute.params.id
  return store.dispatch('kdt/calculatePayments')
    .then(() => {
      return store.dispatch("kdt/getDocumentById", id)
    })
    .catch((err) => {
      _error('Ошибка при расчете платежа"')
      throw err
    })
}

function recalculate(){
  return _calculate()
}
function validate() {
  const requests = store.getters['kdt/getRequests']
  return Promise.all(requests).then(() => {
    const id = router.currentRoute.params.id
    return store.dispatch('kdt/validatePayments', id)
  }).then((res) => {
    if (res.data && !res.data.length) {
      return true
    } else {
      _toggle()
      throw new Error("Имеются ошибки в документе")
    }
  })
}

function validateAndRecalculatePayments() {
  const requests = store.getters['kdt/getRequests']
  return Promise.all(requests).then(() => {
    const id = router.currentRoute.params.id
    return store.dispatch('kdt/validatePayments', id)
  })
    .then(res => {
      res.data && !res.data.length
        ? _calculate()
        : _toggle()
    })
    .catch(() => _error('Ошибка при валидации документа'))
}

export {validateAndRecalculatePayments, validate, recalculate}