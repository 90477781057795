<template>
  <div>
    <v-container
      :id="block_id"
      v-click-outside="triggerOnFocus"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div
            class="statistics-box pa-4"
            :class="{
              'blocked-box': disabledView || canEdit 
            }"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <div class="d-flex align-center mt-2">
                  <span class="font-weight-bold">
                    Включение в стоимость
                  </span>
                  <v-btn
                    width="32px"
                    height="32px"
                    min-width="32px"
                    class="ml-3 btn-edit"
                    style="z-index: 2"
                    @click="canEdit=!canEdit"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col
                v-if="!hasIncludings"
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="document-add elevation-0 px-15"
                  color="transparent"
                  @click="add"
                >
                  Добавить
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <div
                  v-if="hasIncludings"
                  class="d-flex align-center"
                  style="gap:20px;"
                >
                  <div
                    v-for="(header, index) in headers"
                    :key="`h-${index}`"
                    :style="`width:${header.width}`"
                  >
                    <label>{{ header.name }}</label>
                  </div>
                </div>
                <including-cost-item
                  v-for="(item, index) in about_including_cost.including_costs_json"
                  :key="index"
                  :item="item"
                  :items="about_including_cost.including_costs_json"
                  :index="index"
                  :visited="fields_been_visited"
                  @update="update"
                  @remove="remove"
                  @add="add"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-col class="d-flex justify-end">
      <div
        class="d-flex"
      >
        <v-btn
          class="elevation-0 teal lighten-2 rounded-r-0"
          @click="divideCostAndCalculatePayments"
        >
          <v-icon>mdi-slash-forward-box</v-icon>
          Распределить расходы и расcчитать платежи
        </v-btn>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              class="elevation-0 rounded-l-0"
              dark
              v-bind="attrs"
              min-width="40"
              max-width="40"
              v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="validateAndDivideCost"
            >
              Распределить расходы
            </v-list-item>
            <v-list-item
              @click="validateAndRecalculate"
            >
              Рассчитать платежи
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IncludingCostItem from './including-cost-item.vue'
import cloneDeep from "lodash.clonedeep";
import {defaultIncludingCostItem} from "@/components/kdt/including-cost/including-cost";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {
  recalculate,
  validate,
  validateAndRecalculatePayments,
} from "@/components/kdt/controls/payments";

const RECALCULATE_SUCCESS = "Платежи рассчитаны"
const RECALCULATE_ERROR = "Не удалось рассчитать платежи"
const DIVIDE_SUCCESS = "Расходы распределены"
const DIVIDE_ERROR = "Не удалось распределить расходы"
export default {
  components: {
    IncludingCostItem
  },
  mixins: [blockAutoUpdateMixin],
  data: () => ({
    block_id: "kdt-including-cost",
    canEdit: true,
    headers: [
      {
        name: "Вид включения",
        width: "40%"
      },
      {
        name: "Сумма",
        width: "120px;"
      },
      {
        name: "Валюта",
        width: "120px;"
      },
      {
        name: "Для товаров",
        width: "220px"
      },
    ],
    about_including_cost: {
      declaration_id: null,
      including_costs_json: []
    },
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      user: "auth/getUser",
      disabledView: "kdt/getVisibility",
      requests: "kdt/getRequests"
    }),
    hasIncludings(){
      return !!this.about_including_cost.including_costs_json.length
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
        this.$nextTick(() => this.updateEventListeners())
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    divideCostAndCalculatePayments() {
      return Promise.all(this.requests)
        .then(() => validate.call(this))
        .then(this.divideCost)
        .then(this.recalculatePayments)
        .catch((err) => console.error(err))
    },
    recalculatePayments(){
      return recalculate.call(this)
        .then(() => this.$info(RECALCULATE_SUCCESS))
    },
    validateAndRecalculate() {
      return Promise.all(this.requests)
        .then(() => validateAndRecalculatePayments.call(this))
        .then(() => this.$info(RECALCULATE_SUCCESS))
        .catch((error) => {
          this.$error(RECALCULATE_ERROR)
          throw error
        })
    },
    validateAndDivideCost(){
      return validate.call(this)
        .then(this.divideCost)
    },
    divideCost() {
      return Promise.all(this.requests)
        .then(() => this.$store.dispatch("kdt/divideCost"))
        .then(() => this.$store.dispatch('kdt/getDocumentById', this.selected.id))
        .then(() => this.$info(DIVIDE_SUCCESS))
        .catch((error) => {
          this.$error(DIVIDE_ERROR)
          throw error
        })
    },
    readyToUpdate() {
      this.hasChanges = true
      this.fields_been_visited = true
    },
    add() {
      this.about_including_cost.including_costs_json.push(defaultIncludingCostItem())
      this.readyToUpdate()
    },
    update({index, value}) {
      this.about_including_cost.including_costs_json.splice(index, 1, value)
      this.readyToUpdate()
    },
    remove(index) {
      this.about_including_cost.including_costs_json.splice(index, 1)
      this.readyToUpdate()
    },
    setFields() {
      const {declaration_id, including_costs_json} = this.selected?.about_including_cost ?? {}
      this.about_including_cost.declaration_id = declaration_id
      this.about_including_cost.including_costs_json = cloneDeep(including_costs_json || [])
    },
    uploadData() {
      const about_including_cost = convertEmptyStringsToNull(this.about_including_cost)
      return this.$store.dispatch("kdt/uploadBlockData", {
        name: "about_including_cost",
        value: about_including_cost,
      })
    },
  }
};
</script>
