<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
    cols="6"
    order="1"
  >
    <div
      class="statistics-box pa-3 ma-0"
      style="position: relative"
    >
      <btn-can-edit :can-edit.sync="canEdit" />
      <v-row :class="{ 'blocked-box': disabledView || canEdit }">
        <v-col
          cols="8"
          class="d-flex align-center justify-space-between"
        >
          <span class="font-weight-bold">
            14 Декларант
            <block-menu>
              <v-list-item
                tag="button"
                @click="show = true"
              >
                Cохранить организацию в справочник
              </v-list-item>
            </block-menu>
          </span>
          <v-switch
            v-model="declarant_json.individual"
            hide-details="auto"
            dense
            class="ml-4"
            color="#5CB7B1"
            @change="changeIndividual"
          >
            <template #label>
              <label style="font-size: 12px">Физ. лицо</label>
            </template>
          </v-switch>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="search"
            :loading="loading"
            :readonly="loading"
            :items="importers"
            item-text="own_name"
            :filter="filterBySearchField"
            placeholder="Поиск"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            background-color="white"
            dense
            outlined
            hide-details="true"
            @update:search-input="debouncedFetchImporters"
          />
        </v-col>
        <v-col
          cols="12"
          class="pb-0 d-flex"
        >
          <div
            style="width: 12%"
            class="mr-2"
          >
            <label>
              Страна
              <v-autocomplete
                ref="country_letter"
                v-model="declarant_json.country_letter"
                auto-select-first
                :filter="filterBySearchField"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="countries"
                item-text="letterCode"
                item-value="letterCode"
              />
            </label>
          </div>
          <div
            v-show="!declarant_json.individual"
            class="mr-2"
            style="width: 20%"
          >
            <label>
              УНП (ИНН И т.п.)
              <v-text-field
                ref="declarant_inn"
                v-model="declarant.declarant_inn"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="!declarant_json.individual"
            style="width: 68%"
          >
            <label>
              Наименование организации
              <v-text-field
                ref="declarant_name"
                v-model="declarant.declarant_name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="declarant_json.individual"
            class="mr-2"
            style="width: 20%"
          >
            <label>
              Код док-а
              <v-autocomplete
                ref="identity_doc_code"
                v-model="declarant_json.identity_doc_code"
                :items="identityDocumentsFiltered"
                item-text="search"
                auto-select-first
                item-value="code"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
              />
            </label>
          </div>
          <div
            v-show="declarant_json.individual"
            class="mr-2"
            style="width: 23%"
          >
            <label>
              Номер документа
              <v-text-field
                ref="personal_number"
                v-model="declarant.declarant_identity_doc_number"
                v-mask="'SS#######'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
          <div
            v-show="declarant_json.individual"
            style="width: 23%"
            class="mr-2"
          >
            <label>
              Дата документа
              <custom-date-picker
                ref="identity_doc_d_on"
                v-model="declarant_json.identity_doc_d_on"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </label>
          </div>
          <div
            v-show="declarant_json.individual"
            style="width: 26%"
          >
            <label>
              Личный номер
              <v-text-field
                ref="personal_number"
                v-model="declarant_json.personal_number"
                v-mask="'#######S###SS#'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
        </v-col>
        <!--  -->
        <v-col
          v-show="declarant_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="declarant_last_name"
              v-model="declarant.declarant_last_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-show="declarant_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Имя
            <v-text-field
              ref="declarant_first_name"
              v-model="declarant.declarant_first_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-show="declarant_json.individual"
          cols="4"
          class="pb-0"
        >
          <label>
            Отчество
            <v-text-field
              ref="declarant_middle_name"
              v-model="declarant.declarant_middle_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Индекс
            <v-text-field
              ref="postal_index"
              v-model="declarant_json.postal_index"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <label>
            Область/Район
            <v-text-field
              ref="region"
              v-model="declarant_json.region"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <label>
            Город/Деревня
            <v-text-field
              ref="city"
              v-model="declarant_json.city"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="8"
          class="pb-0"
        >
          <label>
            Улица
            <v-text-field
              ref="street_house"
              v-model="declarant_json.street_house"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Дом
            <v-text-field
              ref="house"
              v-model="declarant_json.house"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0 pl-0"
        >
          <label>
            Номер помещ.
            <v-text-field
              ref="room"
              v-model="declarant_json.room"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <!--  -->
        <v-col
          v-if="!contacts.length"
          class="pb-0 d-flex align-end justify-start"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon>
            Контакт
          </v-btn>
        </v-col>
        <v-col
          ref="contacts"
          cols="8"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
        </v-col>

        <!-- Филиал -->
        <v-col
          v-show="!declarant_json.individual"
          cols="12"
        >
          <v-row>
            <v-col
              cols="3"
              class="pb-0"
            >
              <div
                class="font-weight-bold d-flex align-center mt-2"
                :class="{ 'mb-2': collapsedBranch }"
              >
                Филиал
                <v-btn
                  style="z-index: 2"
                  min-width="32px"
                  class="ml-1"
                  text
                  @click="collapsedBranch = !collapsedBranch"
                >
                  <v-icon>
                    {{
                      collapsedBranch ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsedBranch">
            <v-col
              cols="12"
              class="pb-0 d-flex"
            >
              <div
                style="width: 12%"
                class="mr-2"
              >
                <label>
                  Страна
                  <v-autocomplete
                    ref="branch_country_letter"
                    v-model="declarant_json.branch_country_letter"
                    auto-select-first
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    :items="countries"
                    item-text="letterCode"
                    item-value="letterCode"
                    :filter="filterBySearchField"
                  />
                </label>
              </div>
              <div
                class="mr-2"
                style="width: 20%"
              >
                <label>
                  УНП (ИНН И т.п.)
                  <v-text-field
                    ref="declarant_branch_inn"
                    v-model="declarant.declarant_branch_inn"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
              <div style="width: 68%">
                <label>
                  Наименование организации
                  <v-text-field
                    ref="declarant_branch_name"
                    v-model="declarant.declarant_branch_name"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="branch_postal_index"
                  v-model="declarant_json.branch_postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="branch_region"
                  v-model="declarant_json.branch_region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="branch_city"
                  v-model="declarant_json.branch_city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="8">
              <label>
                Улица
                <v-text-field
                  ref="branch_street_house"
                  v-model="declarant_json.branch_street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Дом
                <v-text-field
                  ref="branch_house"
                  v-model="declarant_json.branch_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0 pl-0"
            >
              <label>
                Номер помещ.
                <v-text-field
                  ref="branch_room"
                  v-model="declarant_json.branch_room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center">
                Сведения об уполномоченном экономическом операторе
              </div>
            </v-col>
            <v-col cols="2">
              <label>
                Страна
                <v-autocomplete
                  ref="aeo_country_letter"
                  v-model="declarant_json.aeo_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col cols="10">
              <label>
                Номер по реестру
                <v-autocomplete
                  ref="aeo_reg_number"
                  v-model="declarant_json.aeo_reg_number"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  class="mr-1"
                  :items="aeos"
                  item-value="licenseNumber"
                  item-text="licenseNumber"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <archive
          :show.sync="show"
          :name="declarant.declarant_name"
          @save="saveToArchive"
        />
      </v-row>
    </div>
  </v-col>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import SingleContact from "../single-contact.vue";
import BtnCanEdit from "./btn-can-edit.vue";
import { mapGetters } from "vuex";
import cloneDeep from "lodash.clonedeep";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  getCountryNameByCode,
  getDeclarantFromResident,
} from "@/helpers/catalogs";
import Archive from "./archive.vue";
import contactsMixin from "@/mixins/contacts.mixin";
import { highlightField } from "@/helpers/control";
import { declarant as onDeclarant } from "@/events/statistics/control";
import blockVisibility from "@/mixins/block-visibility";
import { debounce } from "@/helpers/inputs";
import BlockMenu from "@/components/documents/block-menu.vue";
import { uploadDataInjector } from "@/helpers/injectors";

export default {
  components: {
    BlockMenu,
    CustomDatePicker,
    SingleContact,
    Archive,
    BtnCanEdit,
  },
  mixins: [
    blockAutoUpdate,
    filterBySearchFieldMixin,
    blockVisibility,
    contactsMixin,
  ],
  provide() {
    return {
      [uploadDataInjector]: this.uploadIfDirty,
    };
  },
  data: () => ({
    block_id: "kdt-declarant",
    canEdit: true,
    collapsedBranch: true,
    declarant: {
      declaration_id: null,
      declarant_last_name: "",
      declarant_middle_name: "",
      declarant_name: "",
      declarant_branch_inn: "",
      declarant_branch_name: "",
      declarant_first_name: "",
      declarant_identity_doc_number: "",
      declarant_inn: "",
    },
    declarant_json: {
      individual: false,
      aeo_country_letter: "",
      aeo_country_name: "",
      aeo_reg_number: "",
      branch_city: "",
      branch_country_letter: "",
      branch_country_name: "",
      branch_house: "",
      branch_postal_index: "",
      branch_region: "",
      branch_room: "",
      branch_street_house: "",
      city: "",
      country_letter: "",
      country_name: "",
      house: "",
      identity_doc_d_on: "",
      identity_doc_code: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      street_house: "",
    },
    contacts: [],
    branch_contacts: [],
    // search
    loading: false,
    importers: [],
    search: null,
    show: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: "kdt/getSelected",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      aeos: "kdt/getNsiAeos",
      disabledView: "kdt/getVisibility",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    isImport() {
      return this.selected?.type_declaration?.declaration_kind_code === "ИМ"
        ? true
        : false;
    },
    identityDocumentsFiltered() {
      if (this.declarant_json.country_letter) {
        if (
          ["BY", "RU", "KZ", "KG", "AM"].includes(
            this.declarant_json.country_letter
          )
        ) {
          return this.identityDocuments.filter((item) => {
            return item.code.startsWith(this.declarant_json.country_letter);
          });
        }
        return this.identityDocuments.filter((item) => {
          return item.code.startsWith("XX");
        });
      }
      return this.identityDocuments;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.uploadData()
          .then(() => {
            this.$snackbar({
              text: "Обновлено [Декларант]",
              color: "green",
              top: false,
              right: false,
            });
          })
          .catch(() => {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          });
      }
    },
  },
  created() {
    onDeclarant.subscribe(this.highlightField);
    this.debouncedFetchImporters = debounce(this.fetchImporters);
  },
  beforeDestroy() {
    onDeclarant.unsubscribe();
  },
  methods: {
    highlightField,
    saveToArchive(own_name) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: own_name,
        name: this.declarant.declarant_name,
        person_name: null,
        unp: this.declarant.declarant_inn,
        country_letter: this.declarant_json.country_letter,
        country_name: this.declarant_json.country_name,
        region: this.declarant_json.region,
        city: this.declarant_json.city,
        street: this.declarant_json.street_house,
        house: this.declarant_json.house,
        room: this.declarant_json.room,
        postal_index: this.declarant_json.postal_index,
        contacts: this.contacts,
        branch_name: this.declarant.declarant_branch_name,
        branch_unp: this.declarant.declarant_branch_inn,
        branch_country_letter: this.declarant_json.branch_country_letter,
        branch_country_name: this.declarant_json.branch_country_name,
        branch_region: this.declarant_json.branch_region,
        branch_city: this.declarant_json.branch_city,
        branch_street: this.declarant_json.branch_street_house,
        branch_house: this.declarant_json.branch_house,
        branch_room: this.declarant_json.branch_room,
        branch_postal_index: this.declarant_json.branch_postal_index,
        subject_doc_num: null,
        subject_doc_date: null,
        branch_contacts: [],
      };

      // подгонка контактов
      payload.contacts.map((i) => {
        i.contact = i.number;
        delete i.number;
      });

      this.$store
        .dispatch("division/saveResidentRaw", payload)
        .then(() => this.$info("Справочник обновлен"))
        .catch((err) => {
          const msg =
            err.response.status === 400
              ? err.response.data || "Ошибка"
              : "Ошибка";
          this.$error(msg);
        });
    },
    updateFieldsOnSearch(item) {
      const { declarant, declarant_json } = getDeclarantFromResident({
        item,
        declaration_id: this.selected.declarant.declaration_id,
      });

      this.declarant = declarant;
      this.declarant_json = declarant_json;
    },
    debouncedFetchImporters() {},
    fetchImporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("division/searchResident", value)
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp}-${i.city}-${i.street}`,
              };
            });
            this.importers = [...items];
          })
          .catch(() => (this.loading = false));
      }
    },
    changeIndividual() {
      if (this.declarant_json.individual === false) {
        this.declarant_json.identity_doc_code = null;
        this.declarant_json.personal_number = null;
        this.declarant_json.personal_number = null;
        this.declarant.declarant_identity_doc_number = null;
        this.declarant_json.identity_doc_d_on = null;
        this.declarant.declarant_last_name = null;
        this.declarant.declarant_first_name = null;
        this.declarant.declarant_middle_name = null;
      } else {
        this.declarant.declarant_inn = null;
        this.declarant.declarant_name = null;
      }
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addContactItem(type = "") {
      const contact = {
        code: "",
        name: "",
        number: "",
      };
      if (type === "branch") {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === "branch") {
        this.branch_contacts.splice(index, 1);
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    contactsUpdater(payload, type) {
      if (type === "branch") {
        this.branch_contacts[payload.index] = payload.cont_item;
      } else {
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    setFields() {
      const declarant = this.selected.declarant;
      const declarant_json = this.selected.declarant.declarant_json;

      const contacts = declarant_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);

      Object.keys(this.declarant).forEach((key) => {
        if (key in declarant) {
          this.declarant[key] = declarant[key];
        }
      });

      Object.keys(this.declarant_json).forEach((key) => {
        if (key in declarant_json) {
          this.declarant_json[key] = declarant_json[key];
        }
      });
    },
    uploadIfDirty() {
      return this.isDirty() ? this.uploadData() : Promise.resolve();
    },
    uploadData() {
      const declarant = this.convertEmptyStringsToNull({
        ...this.declarant,
        declarant_json: {
          ...this.declarant_json,
          country_name:
            getCountryNameByCode(
              this.countries,
              this.declarant_json.country_letter
            ) || null,
          branch_country_name:
            getCountryNameByCode(
              this.countries,
              this.declarant_json.branch_country_letter
            ) || null,
          aeo_country_name:
            getCountryNameByCode(
              this.countries,
              this.declarant_json.aeo_country_letter
            ) || null,
          contacts: this.contacts,
          branch_contacts: [],
        },
      });
      return this.$store.dispatch("kdt/uploadBlockData", {
        name: "declarant",
        value: declarant,
      });
    },
  },
};
</script>
