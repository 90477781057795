<template>
  <v-col
    cols="8"
  >
    <div class="d-flex align-center justify-space-between">
      <div>
        <p>
          {{ filler.last_name }}
          <span v-if="filler.first_name">{{ filler.first_name[0] }}.</span>
          <span v-if="filler.middle_name">{{ filler.middle_name[0] }}.</span>
        </p>
      </div>
      <div>
        <p>
          {{ filler.position }}
        </p>
      </div>
      <div>
        <p>
          {{ filler.auth_doc_code }}
        </p>
      </div>
      <div>
        <strong> № {{ filler.auth_doc_number }}</strong>
        по {{ formatDate(filler.auth_doc_d_off) }}
      </div>
      <div>
        <div class="bordered pa-2">
          <strong>{{ filler.customs_agent_contract_num }}</strong>
          с {{ formatDate(filler.customs_agent_contract_date) }}
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import formatDate from '@/mixins/format-date'

export default {
  mixins: [formatDate],
  props: {
    filler: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.bordered{
    border: 1px #6E6E6E solid;
    border-radius: 6px;
    margin-right: auto;
}
p{
    margin-bottom: 0;
}
</style>
